import React, { useEffect, useRef, useState } from "react";
import "./ProduktetDetails.scss";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import Carousel from "react-elastic-carousel";
import ProduktetENdryshmeCard from "../../components/Home/ProduktetENdryshme/ProduktetENdryshmeCard/ProduktetENdryshmeCard";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 150, itemsToShow: 1 },
  { width: 650, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const ProduktetDetails = () => {
  const { id, title } = useParams();

  const [pageData, setPageData] = useState([]);
  const [originalPageData, setOriginalPageData] = useState([]);

  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const onNextStart = () => {
    setCurrentIndex((prevIndex) => prevIndex + 3);
    carouselRef.current.goTo(currentIndex + 3);
  };

  const onPrevStart = () => {
    const newIndex = currentIndex - 3 < 0 ? 0 : currentIndex - 3;
    setCurrentIndex(newIndex);
    carouselRef.current.goTo(newIndex);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}API/ProduktetENdryshme.json`)
      .then((res) => {
        setPageData(res.data);
        setOriginalPageData(res.data);
      })
      .catch((error) => {
        console.error("ProduktetENdryshme : ", error);
      });
  }, []);

  const item = pageData.find((item) => item.id === Number(id));

  const itemImage = item && item.companyIcon

  const imageItemDiv = {
    backgroundImage: `url(../../assets/ProduktetDetails/${itemImage})`
}
  //   const replaceVizioni = (text) => {
  //     return text.replace(/Vizioni/g, '<span class="vizioni">Vizioni</span>');
  //   };

  return (
    <div className="ProduktetDetails">
      <div className="ProduktetDetails__inside">
        <div className="ProduktetDetails__1">
          <div className="left">
            <Link to="/produkte" className="prapaLink medium18">
              <HiOutlineArrowNarrowLeft />
              Prapa
            </Link>

            {item && item.company ? (
              <p className="makeInRowProduct medium20">
                <div className="productIcon" 
                    style={imageItemDiv}
                ></div>
                {item && item.company}
              </p>
            ) : null}

            <p className="semi40Title">{item && item.productTitle}</p>

            <p className="regular18">{item && item.produktDSC}</p>

            <div className="btnTestimiAndCertifikimi">
              <div className="divBtn medium16">
                <span className="divBtnIcon1"></span>
                {item && item.orangeName}
              </div>

              <div className="divBtn medium16">
                <span className="divBtnIcon2"></span>
                Çertifikimi:
                <span className="makeTheseSpanGreen medium16">
                  {item && item.certifikimiNumber}
                </span>
                {item && item.certifikimi}
              </div>
            </div>

            <div className="vlersimi1 regular15">
              <div className="allStars">
                <div className="yellowStar"></div>
                <div className="yellowStar"></div>
                <div className="yellowStar"></div>
                <div className="yellowStar"></div>
                <div className="yellowStar"></div>
              </div>
              Vlerësimet nga {item && item.vlersimi}
            </div>

            <div className="shkruajNjeKoment medium16">
              <div className="grayStar"></div>
              Shkruaj një koment
            </div>
          </div>

          <div className="right">
            <div
              className="insideRight1"
              style={{
                content: `url(${
                  process.env.REACT_APP_PRODUKTETENDRYSHME_IMAGE
                }/${item && item.productImage})`,
              }}
            ></div>
          </div>
        </div>

        <div className="ProduktetDetails__2">
          <p className="monstera30Bold">Rreth Prodhuesit</p>

          <p className="regular20">{item && item.rrethProdhuesit[0].misioni}</p>

          <p className="regular20">{item && item.rrethProdhuesit[1].misioni}</p>
        </div>

        <div className="ProduktetDetails__3">
          <p className="monstera30Bold">Analizat Kimike</p>

          <p className="regular20">{item && item.analizatDSC}</p>

          <div className="analizatKimike">
            {item &&
              item.analizatKimike.map((itemMap, index) => (
                <div className="analizat" key={index}>
                  <div className="analizatKimike__detail" key={index}>
                    <p className="medium20">
                      {itemMap && itemMap.analizatTitle}
                    </p>

                    <div className="btnInRight medium20">
                      {itemMap && itemMap.analizatInNumber}
                    </div>
                  </div>

                  <div className="chemical" key={index}>
                    {itemMap.chemicalElements.map((itemChemical) => (
                      <div className="analizatInPercent">
                        <span className="with14Font">
                          {itemChemical && itemChemical.chemicalElementsName}
                        </span>

                        <span className="mgL">
                          {itemChemical &&
                            itemChemical.chemicalElementsQuantity}
                        </span>

                        <span className="mgL">mg/L</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="ProduktetDetails__4">
          <div className="left">
            <p className="monstera30Bold">Çertifikatat</p>

            <p className="regular20">
              {item && item.certifikatat[0].certifikatatDSC}
            </p>

            <p className="regular20">
              {item && item.certifikatat[1].certifikatatDSC}
            </p>
          </div>

          <div className="right">
            <div className="image1"></div>
            <div className="imageLogo"></div>
          </div>
        </div>

        <div className="ProduktetDetails__5">
          <p className="monstera30Bold">Reviews</p>

          <div className="insideReview">
            <div className="reviewBtn medium16">Reviews 0</div>
          </div>
        </div>

        <div className="ProduktetDetails__6">
          <p className="medium16">Më shumë produkte prej këtij prodhuesi</p>

          <Carousel
            className="carouselInLexoniTeRejatTona slider styled-arrows"
            breakPoints={breakPoints}
            ref={carouselRef}
            disableArrowsOnEnd={false}
            onChange={(currentItem, pageIndex) => setCurrentIndex(pageIndex)}
          >
            {pageData.map((item) => (
              <ProduktetENdryshmeCard
                key={item.id}
                productImg={`${process.env.REACT_APP_PRODUKTETENDRYSHME_IMAGE}${item.productImage}`}
                zbritje={item.zbritje}
                orangeName={item.orangeName}
                dataEPostimit={item.dataEPostimit}
                certifikimiNumber={item.certifikimiNumber}
                certifikimi={item.certifikimi}
                productTitle={item.productTitle}
                vlersimi={item.vlersimi}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default ProduktetDetails;
