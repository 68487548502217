import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import './dark.scss'
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './pages/Home/Home';
import ScrollToTop from './ScrollToTop';
import Navbar from './components/shared/Navbar/Navbar';
import Footer from './components/shared/Footer/Footer';
import TestimiIUshqimeve from './pages/TestimiIUshqimeve/TestimiIUshqimeve';
import TestimiIPijeve from './pages/TestimiIPijeve/TestimiIPijeve';
import TestimiIUshqimeveBIO from './pages/TestimiIUshqimeveBIO/TestimiIUshqimeveBIO';
import TestimiIPaketimit from './pages/TestimiIPaketimit/TestimiIPaketimit';
import KontaktoniEkipinTone from './pages/KontaktoniEkipinTone/KontaktoniEkipinTone';
import Regjistrohu from './pages/Regjistrohu/Regjistrohu';
import Login from './pages/Login/Login';
import TestimiProduktevePage from './pages/TestimiProduktevePage/TestimiProduktevePage';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { translate } from './translation/translate';
import ProduktetDetails from './pages/ProduktetDetails/ProduktetDetails';

function App(props) {
  const language = useSelector((state) => state.language.language);
  return (
    <div className="App">
      <IntlProvider
        locale={language}
        formats={{ number: 'en' }}
        messages={translate[language]}
      >
        <BrowserRouter>
          <ScrollToTop />
          <Navbar />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/TestimiIUshqimeve' element={<TestimiIUshqimeve />} />
            <Route path='/TestimiIPijeve' element={<TestimiIPijeve />} />
            <Route path='/TestimiIUshqimeveBIO' element={<TestimiIUshqimeveBIO />} />
            <Route path='/TestimiIPaketimit' element={<TestimiIPaketimit />} />
            <Route path='/KontaktoniEkipinTone' element={<KontaktoniEkipinTone />} />
            <Route path='/Regjistrohu' element={<Regjistrohu />} />
            <Route path='/Login' element={<Login />} />
            <Route path='/produkte' element={<TestimiProduktevePage />} />
            <Route path='/produkte/:id/:title' element={<ProduktetDetails />} />


            

          </Routes>
          <Footer />
        </BrowserRouter>
      </IntlProvider>
    </div>
  );
}

export default App;
