import { Link } from "react-router-dom";
import "./Navbar.scss";
import { MdKeyboardArrowDown } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import MobileNavbar from "../MobileNavbar/MobileNavbar";
import { RxHamburgerMenu } from "react-icons/rx";
import { useEffect, useRef, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import DropDownCard from "../DropDownCard/DropDownCard";
import { IoIosArrowDroprightCircle } from "react-icons/io";

import perNe from "../../../assets/RrethNesh/perNe.svg";
import teRejat from "../../../assets/RrethNesh/teRejat.svg";
import termat from "../../../assets/RrethNesh/termat.svg";
import ekipaJuaj from "../../../assets/RrethNesh/ekipaJuaj.svg";
import privatesia from "../../../assets/RrethNesh/privatesia.svg";
import faq from "../../../assets/RrethNesh/perNe.svg";
import mbeshtetja from "../../../assets/RrethNesh/mbeshtetja.svg";
import partneret from "../../../assets/RrethNesh/partneret.svg";

import testimiProdukteve from "../../../assets/Sherbimet/testimiProdukteve.svg";
import testimeLaboratorike from "../../../assets/Sherbimet/testimeLaboratorike.svg";
import testimIUshqimeve from "../../../assets/Sherbimet/testimIUshqimeve.svg";
import testimiISigurise from "../../../assets/Sherbimet/testimiISigurise.svg";
import testimiIPijeve from "../../../assets/Sherbimet/testimiIPijeve.svg";
import analizaLaboratorike from "../../../assets/Sherbimet/analizaLaboratorike.svg";
import testimiBio from "../../../assets/Sherbimet/analizaLaboratorike.svg";
import testimiPaketimit from "../../../assets/Sherbimet/testimiPaketimit.svg";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const navbarRef = useRef(null);

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [navbarRef]);

  const RrethNeshData = [
    {
      id: 1,
      icon: perNe,
      dataTitle: "Për ne",
      dataDsc: "Rreth Testkos",
      toWhere: "/",
    },

    {
      id: 2,
      icon: teRejat,
      dataTitle: "Të Rejat",
      dataDsc: "Lexoni më të rejat",
      toWhere: "/",
    },

    {
      id: 3,
      icon: termat,
      dataTitle: "Termat",
      dataDsc: "Termat dhe kondicionet",
      toWhere: "/",
    },

    {
      id: 4,
      icon: ekipaJuaj,
      dataTitle: "Ekpia Jonë",
      dataDsc: "Ekpia e Testkos",
      toWhere: "/",
    },

    {
      id: 5,
      icon: privatesia,
      dataTitle: "Privatesia",
      dataDsc: "Privatesia e prodhuesit",
      toWhere: "/",
    },

    {
      id: 6,
      icon: faq,
      dataTitle: "FAQ",
      dataDsc: "Pyetjet & përgjigjet",
      toWhere: "/",
    },

    {
      id: 7,
      icon: mbeshtetja,
      dataTitle: "Mbështetja",
      dataDsc: "Mbështetja jonë",
      toWhere: "/",
    },

    {
      id: 8,
      icon: partneret,
      dataTitle: "Partnerët",
      dataDsc: "Partnerët tanë",
      toWhere: "/",
    },
  ];

  const SherbimetData = [
    {
      id: 1,
      icon: testimiProdukteve,
      dataTitle: "Testimi I Produkteve",
      dataDsc: "Testimi lloj lloj produkteve",
      toWhere: "/",
    },

    {
      id: 2,
      icon: testimeLaboratorike,
      dataTitle: "Testime Laboratorike",
      dataDsc: "Testet laboratorike",
      toWhere: "/",
    },

    {
      id: 3,
      icon: testimIUshqimeve,
      dataTitle: "Testimi I Ushqimeve",
      dataDsc: "Testet e ushqimeve",
      toWhere: "/TestimiIUshqimeve",
    },

    {
      id: 4,
      icon: testimiISigurise,
      dataTitle: "Testimi I Sigurisë së Produktit",
      dataDsc: "Siguria e produktit",
      toWhere: "/",
    },

    {
      id: 5,
      icon: testimiIPijeve,
      dataTitle: "Testimi I Pijeve",
      dataDsc: "Pijet alkoholike dhe jo alkoholike",
      toWhere: "/TestimiIPijeve",
    },

    {
      id: 6,
      icon: analizaLaboratorike,
      dataTitle: "Analiza Laboratorike",
      dataDsc: "Analizat laboratorike",
      toWhere: "/",
    },

    {
      id: 7,
      icon: testimiBio,
      dataTitle: "Testimi I Ushqimeve BIO",
      dataDsc: "Testimi i prodhimeve BIO",
      toWhere: "/TestimiIUshqimeveBIO",
    },

    {
      id: 8,
      icon: testimiPaketimit,
      dataTitle: "Testimi I Paketimit",
      dataDsc: "Paketimi i sigurt i produktit",
      toWhere: "/TestimiIPaketimit",
    },
  ];

  return (
    <nav className="Navbar">
      <Link to="/" className="Navbar__1"></Link>

      <div className="Navbar__2">
        <div className="navbarItems rrethNesh">
          <Link className="regular16 rrethNesh">
            Rreth Nesh
            <MdKeyboardArrowDown />
          </Link>

          <div className="openNavmenu">
            {RrethNeshData.map((item) => (
              <DropDownCard
                key={item.id}
                icon={item.icon}
                dataTitle={item.dataTitle}
                dataDsc={item.dataDsc}
                toWhere={item.toWhere}
              />
            ))}
          </div>
        </div>

        <div className="navbarItems sherbimet">
          <Link className="regular16 sherbimet">
            Shërbimet
            <MdKeyboardArrowDown />
          </Link>

          <div className="openNavmenu openNavmenu2">
            <div className="leftOpenNavMenu">
              <p className="regular16">Shërbimet Tona</p>
              <div className="leftOpenNavMenu__inside">
                {SherbimetData.map((item) => (
                  <DropDownCard
                    key={item.id}
                    icon={item.icon}
                    dataTitle={item.dataTitle}
                    dataDsc={item.dataDsc}
                    toWhere={item.toWhere}
                  />
                ))}
              </div>

              <Link to={'/produkte'} className="medium15 changeColorToMainColor">
                Shiko të gjitha shërbimet
                <IoIosArrowDroprightCircle />
              </Link>
            </div>

            <div className="rightNavMenu">
              <div className="sherbimetIcon"></div>

              <div className="rightNavMenu__texts">
                <div className="rightNavMenu__inside">
                  <p className="medium15">Strategjija</p>

                  <p className="regular14">
                    Strategji transparente për të gjitha bizneset
                  </p>
                </div>

                <div className="rightNavMenu__inside">
                  <p className="medium15">Filialet & Partnerët</p>

                  <p className="regular14">
                    Ne kemi shërbimet më të mira për ju dhe biznesin tuaj.
                  </p>
                </div>

                <div className="rightNavMenu__inside">
                  <p className="medium15">
                    Nuk mund të gjeni atë që ju nevojitet?
                  </p>

                  <p className="regular14">Kontaktoni agjentët tanë.</p>
                </div>
              </div>

              <Link to="/KontaktoniEkipinTone" className="kontakto">Kontakto</Link>
            </div>
          </div>
        </div>

        <div className="navbarItems">
          <Link className="regular16">Resurset</Link>
        </div>

        <div className="navbarItems">
          <Link className="regular16">Karriera</Link>
        </div>

        <div className="navbarItems">
          <Link className="regular16">Blog</Link>
        </div>

        <div className="navbarItems">
          <Link to="/KontaktoniEkipinTone" className="regular16">Kontakti</Link>
        </div>
      </div>

      <div className="Navbar__3">
        <div className="navbar__search">
          <CiSearch />
          <input type="text" placeholder="Kërko" className="regular16" />
        </div>

        <Link to="/Login" className="regular16 kycu">
          Kyçu
        </Link>

        <Link to="/Regjistrohu" className="regular16 regjistrohu">
          Regjistrohu
        </Link>
      </div>

      <div className="mobileNavbar">
        <Link to="/" className="mobileLogo"></Link>

        <div className="mobileMenu" onClick={handleOpenMenu}>
          {openMenu ? (
            <IoCloseOutline className="makeSvgWhite" />
          ) : (
            <RxHamburgerMenu />
          )}
        </div>

        <div
          ref={navbarRef}
          className={`closeMenu ${openMenu ? "openMenu" : ""}`}
        >
          <MobileNavbar />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
