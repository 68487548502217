import React, { useRef } from 'react'
import './LexoniTeRejatTona.scss'
import Carousel from "react-elastic-carousel";
import Btn from '../../shared/Btn/Btn';
import LexoniTeRejatTonaCard from '../../shared/LexoniTeRejatTonaCard/LexoniTeRejatTonaCard';

import image1 from '../../../assets/LexoniTeRejatTona/img1.webp'
import image2 from '../../../assets/LexoniTeRejatTona/img2.webp'
import image3 from '../../../assets/LexoniTeRejatTona/img3.webp'
import image4 from '../../../assets/LexoniTeRejatTona/img4.webp'

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 150, itemsToShow: 1 },
  { width: 650, itemsToShow: 2 },
  { width: 1200, itemsToShow: 4 },
];


const LexoniTeRejatTona = () => {

    const LexoniData = [
        {
            lexoniId: 1,
            lexoniImg: image1,
            lexoniDataWhen: "05.06.2021",
            lexionTitle: "Testimet e Produkteve  BIO në pjesën kryesore të Kosovës",
            lexoniDsc: "Testimi disa javësh në pjesën kryesore të Kosovës kryesisht Produktet regjinonale...",
            lexoniSecondDsc: "Ushqimet organike dallohet për të tilla nga mënyra se si një produkt ose ushqim është rritur dhe përpunuar."
        },

        {
            lexoniId: 2,
            lexoniImg: image2,
            lexoniDataWhen: "15.06.2021",
            lexionTitle: "Qertifikimi I Produkteve Në Vitin 2021",
            lexoniDsc: "Bujqësia organike kufizon në mënyrë të rreptë përdorimin e lëndëve shtesë dhe ndihmuesit...",
            lexoniSecondDsc: "Qertifikimi i produkteve për vitin 2021 është një hap i rëndësishëm për të garantuar standardet e larta të cilësisë dhe sigurisë në prodhimin e ushqimit. Bujqësia organike ka nevojë për një përpjekje të përbashkët për të siguruar se përdorimi i lëndëve shtesë dhe ndihmës është në përputhje me praktikat më të mira të bujqësisë së qëndrueshme."
        },

        {
            lexoniId: 3,
            lexoniImg: image3,
            lexoniDataWhen: "23.04.2021",
            lexionTitle: "Produktet Organike Në Supermarketet e Kosovës",
            lexoniDsc: "Çdo herë që blini një produkt organik nga supermarket juaj apo zgjidhni një verë organike...",
            lexoniSecondDsc: "Produktet organike në supermarketet e Kosovës ofrojnë një zgjedhje të shëndetshme për konsumatorët. Ato janë prodhuar duke iu përmbajtur standardeve të larta të cilësisë dhe sigurisë, dhe janë një mënyrë e shkëlqyer për të mbështetur bujqësinë vendase dhe praktikat e qëndrueshme të bujqësisë."
        },

        {
            lexoniId: 4,
            lexoniImg: image4,
            lexoniDataWhen: "05.12.2020",
            lexionTitle: "Transformoni Biznesin Tuaj Me Certifikim Te Produkteve",
            lexoniDsc: "Mund të jeni të sigurt se ato janë prodhuar sipas rregullave strikte që respektojnë ...",
            lexoniSecondDsc: "Certifikimi i produkteve është një mjet i fuqishëm për të transformuar biznesin tuaj duke e bërë atë më të konkurrueshëm në treg. Me certifikimin e produkteve, ju jepni një sinjal të qartë për konsumatorët se produktet tuaja janë të cilësisë së garantuar dhe të prodhuara me kujdes të përmbajnë standardet më të larta të sigurisë dhe cilësisë."
        },

        {
            lexoniId: 5,
            lexoniImg: image1,
            lexoniDataWhen: "05.06.2021",
            lexionTitle: "Testimet e Produkteve  BIO në pjesën kryesore të Kosovës",
            lexoniDsc: "Testimi disa javësh në pjesën kryesore të Kosovës kryesisht Produktet regjinonale...",
            lexoniSecondDsc: "Ushqimet organike dallohet për të tilla nga mënyra se si një produkt ose ushqim është rritur dhe përpunuar."
        },

        {
            lexoniId: 6,
            lexoniImg: image2,
            lexoniDataWhen: "15.06.2021",
            lexionTitle: "Qertifikimi I Produkteve Në Vitin 2021",
            lexoniDsc: "Bujqësia organike kufizon në mënyrë të rreptë përdorimin e lëndëve shtesë dhe ndihmuesit...",
            lexoniSecondDsc: "Qertifikimi i produkteve për vitin 2021 është një hap i rëndësishëm për të garantuar standardet e larta të cilësisë dhe sigurisë në prodhimin e ushqimit. Bujqësia organike ka nevojë për një përpjekje të përbashkët për të siguruar se përdorimi i lëndëve shtesë dhe ndihmës është në përputhje me praktikat më të mira të bujqësisë së qëndrueshme."
        },

        {
            lexoniId: 7,
            lexoniImg: image3,
            lexoniDataWhen: "23.04.2021",
            lexionTitle: "Produktet Organike Në Supermarketet e Kosovës",
            lexoniDsc: "Çdo herë që blini një produkt organik nga supermarket juaj apo zgjidhni një verë organike...",
            lexoniSecondDsc: "Produktet organike në supermarketet e Kosovës ofrojnë një zgjedhje të shëndetshme për konsumatorët. Ato janë prodhuar duke iu përmbajtur standardeve të larta të cilësisë dhe sigurisë, dhe janë një mënyrë e shkëlqyer për të mbështetur bujqësinë vendase dhe praktikat e qëndrueshme të bujqësisë."
        },

        {
            lexoniId: 8,
            lexoniImg: image4,
            lexoniDataWhen: "05.12.2020",
            lexionTitle: "Transformoni Biznesin Tuaj Me Certifikim Te Produkteve",
            lexoniDsc: "Mund të jeni të sigurt se ato janë prodhuar sipas rregullave strikte që respektojnë ...",
            lexoniSecondDsc: "Certifikimi i produkteve është një mjet i fuqishëm për të transformuar biznesin tuaj duke e bërë atë më të konkurrueshëm në treg. Me certifikimin e produkteve, ju jepni një sinjal të qartë për konsumatorët se produktet tuaja janë të cilësisë së garantuar dhe të prodhuara me kujdes të përmbajnë standardet më të larta të sigurisë dhe cilësisë."
        },
    ]

      const carouselRef = useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo("single-slide".length);
    }
  };


  return (
    <div className='LexoniTeRejatTona'>
        <p className='medium40'>
        Lexoni të rejat tona
        </p>


        <Carousel
        className="carouselInLexoniTeRejatTona slider styled-arrows"
        breakPoints={breakPoints}
        ref={carouselRef}
        onPrevStart={onPrevStart}
        onNextStart={onNextStart}
        disableArrowsOnEnd={false}
      >
        {
            LexoniData.map((item) => (
                <LexoniTeRejatTonaCard 
                    key={item.lexoniId}
                    lexoniImg={item.lexoniImg}
                    lexoniDataWhen={item.lexoniDataWhen}
                    lexionTitle={item.lexionTitle}
                    lexoniDsc={item.lexoniDsc}
                    lexoniSecondDsc={item.lexoniSecondDsc}
                />
            ))
        }

      </Carousel>


      <Btn textBtn={"Shikoni mË shumË"}/>


    </div>
  )
}

export default LexoniTeRejatTona