import React from 'react'
import './LexoniTeRejatTonaCard.scss'

const LexoniTeRejatTonaCard = ({ lexoniImg, lexoniDataWhen, lexionTitle, lexoniDsc, lexoniSecondDsc}) => {

    const lexoniImgDiv = {
        backgroundImage: `url(${lexoniImg})`
    }


  return (
    <div className='LexoniTeRejatTonaCard'>
        <div className="LexoniTeRejatTonaCard__img" style={lexoniImgDiv}></div>

        <div className="LexoniTeRejatTonaCard__bottom">
            <div className='LexoniTeRejatTonaCard__1'>
                <button>
                New
                </button>

                <span className='medium15'>
                {lexoniDataWhen}
                </span>
            </div>

            <p className="LexoniTeRejatTonaCard__2">
            {lexionTitle}
            </p>

            <div className="LexoniTeRejatTonaCard__3">
                <p className="medium16">
                {lexoniDsc}
                </p>

                <p className="medium16">
                {lexoniSecondDsc}
                </p>
            </div>

        </div>

    </div>
  )
}






export default LexoniTeRejatTonaCard