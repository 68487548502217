import React, { useEffect, useState } from 'react'
import './BottomProduktet.scss'
import { IoIosArrowDown } from "react-icons/io";
import { BiMenuAltLeft } from "react-icons/bi";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { FaList } from "react-icons/fa";
import axios from "axios";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { IoIosArrowDropdown } from "react-icons/io";
import ProduktetENdryshmeCard from '../../Home/ProduktetENdryshme/ProduktetENdryshmeCard/ProduktetENdryshmeCard';

const BottomProduktet = () => {
    const[openCategories, setOpenCategories] = useState(true)
    const[openReviews, setOpenReviews] = useState(true)
    const[openFilter, setOpenFilter] = useState(false)

    const [pageData, setPageData] = useState([]);
    const [originalPageData, setOriginalPageData] = useState([]);
    const [inGrid, inLine] = useState(false);
    const [perPageNumber, setPerPageNumber] = useState(9);

    const [selectedCategory, setSelectedCategory] = useState(["All"]);
    const [selectStar, setSelectStar] = useState([]);
    const [sortOrder, setSortOrder] = useState("ASC");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}API/ProduktetENdryshme.json`)
      .then((res) => {
        setPageData(res.data);
        setOriginalPageData(res.data);
      })
      .catch((error) => {
        console.error("ProduktetENdryshme : ", error);
      });
  }, []);


  // const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm] = useState("");
//   const handleSearchTerm = (searchTerm) => {
//     setSearchTerm(searchTerm);
//     const filteredDataBySearch = originalPageData.filter((item) =>
//       item.name.toLowerCase().includes(searchTerm.toLowerCase())
//       );
//     setPageData(filteredDataBySearch);
//   };

  const handleGrid = () => {
    inLine(!inGrid);
  };


  const handleSort = (property) => {
    const sortedData = [...filteredData].sort((a, b) => {
      if (sortOrder === "ASC") {
        return a[property] > b[property] ? 1 : -1;
      } else {
        return a[property] < b[property] ? 1 : -1;
      }
    });

    setPageData(sortedData);
    setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
  };


  const handleCategorySelect = (orangeName) => {
    setSelectedCategory(orangeName);
  };



    //Star   
  const handleStarSelect = (star2) => {
    const starFilterMap = {
      "5Star": { star: 5 },
      "4Star": { star: 4 },
      "3Star": { star: 3 },
      "2Star": { star: 2 },
      "1Star": { star: 1 },
    };

    const newSelectStar = selectStar.includes(star2)
      ? selectStar.filter((selectedStar) => selectedStar !== star2)
      : [...selectStar, star2];

    const filteredDataByStar = newSelectStar.length
      ? originalPageData.filter((item) =>
          newSelectStar.some(
            (selectedStar) => item.certifikimiNumber === starFilterMap[selectedStar].star
          )
        )
      : originalPageData;

    setSelectStar(newSelectStar);
    setPageData(filteredDataByStar);
  };

    const filteredData = pageData.filter(
      (item) =>
      (selectedCategory.includes("All") ||
        item.kategori === selectedCategory)
    );
  

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = perPageNumber;
  // const lastIndex = currentPage * recordsPerPage;
  // const firstIndex = lastIndex - recordsPerPage;
  // const records = pageData.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredData.length / recordsPerPage);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const numbersToShow = 3;
  const totalPages = npage;

  const getPageNumbers = () => {
    const midNumber = Math.floor(numbersToShow / 2);
    let startPage = Math.max(1, currentPage - midNumber);
    let endPage = Math.min(startPage + numbersToShow - 1, totalPages);

    if (endPage - startPage + 1 < numbersToShow) {
      startPage = Math.max(1, endPage - numbersToShow + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };
  // End Pagination


  const filteredAndPaginatedData = filteredData.slice(
    (currentPage - 1) * perPageNumber,
    currentPage * perPageNumber
  );

  return (
    <div className='BottomProduktet'>
        <div className="forMobile" onClick={() => setOpenFilter(!openFilter)}>
            <p>
                Filters 
                <IoIosArrowDropdown className={`${openFilter ? "" : "rotateSvg"}`} />
            </p>
        </div>

      <div className={`bottomEducation__left ${openFilter ? "openFilter" : "closeFilter"}`}>

        <div className="bottomEducation__left__options bottomEducation__left__1">
          <h2 onClick={() => setOpenCategories(!openCategories)} className='leftBottom__produktetMake20'>
            <FormattedMessage
              id="BottomEducation__1"
              defaultMessage={"Kategoritë"}
            />
          </h2>

          <ul className={`mobileEducation ${openCategories ? "hide" : "show"}`}> 
            <li>
              <input
                type="checkbox"
                id="Testimi-i-Produkteve"
                checked={selectedCategory.includes("Testimi i Produkteve")}
                onChange={() => handleCategorySelect("Testimi i Produkteve")}
              />
              <label htmlFor="Testimi-i-Produkteve">
                <span className="bottomIcon bottom__icon1"></span>
                <FormattedMessage
                  id="BottomEducation__3"
                  defaultMessage={"Testimi i Produkteve"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Testimi-i-Ushqimeve"
                checked={selectedCategory.includes("Testimi i Ushqimeve")}
                onChange={() => handleCategorySelect("Testimi i Ushqimeve")}
              />
              <label htmlFor="Testimi-i-Ushqimeve">
                <span className="bottomIcon bottom__icon2"></span>
                <FormattedMessage
                  id="BottomEducation__4"
                  defaultMessage={"Testimi i Ushqimeve"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Testimi-i-Pijeve"
                checked={selectedCategory.includes("Testimi i Pijeve")}
                onChange={() => handleCategorySelect("Testimi i Pijeve")}
              />
              <label htmlFor="Testimi-i-Pijeve">
                <span className="bottomIcon bottom__icon3"></span>
                <FormattedMessage
                  id="BottomEducation__5"
                  defaultMessage={"Testimi i Pijeve"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="TestimiiUshqimeveBIO"
                checked={selectedCategory.includes("Testimi i Ushqimeve BIO")}
                onChange={() => handleCategorySelect("Testimi i Ushqimeve BIO")}
              />
              <label htmlFor="TestimiiUshqimeveBIO">
                <span className="bottomIcon bottom__icon4"></span>
                <FormattedMessage
                  id="BottomEducation__6"
                  defaultMessage={"Testimi i Ushqimeve BIO"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Testime-Laboratorike"
                checked={selectedCategory.includes("Testime Laboratorike")}
                onChange={() => handleCategorySelect("Testime Laboratorike")}
              />
              <label htmlFor="Testime-Laboratorike">
                <span className="bottomIcon bottom__icon5"></span>
                <FormattedMessage
                  id="BottomEducation__7"
                  defaultMessage={"Testime Laboratorike"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Testimi-i-Sigurisë-së-Produktit"
                checked={selectedCategory.includes("Testimi i Sigurisë së Produktit")}
                onChange={() => handleCategorySelect("Testimi i Sigurisë së Produktit")}
              />
              <label htmlFor="Testimi-i-Sigurisë-së-Produktit">
                <span className="bottomIcon bottom__icon6"></span>
                <FormattedMessage
                  id="BottomEducation__8"
                  defaultMessage={"Testimi i Sigurisë së Produktit"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Analiza-Laboratorike"
                checked={selectedCategory.includes("Analiza Laboratorike")}
                onChange={() => handleCategorySelect("Analiza Laboratorike")}
              />
              <label htmlFor="Analiza-Laboratorike">
                <span className="bottomIcon bottom__icon7"></span>
                <FormattedMessage
                  id="BottomEducation__9"
                  defaultMessage={"Analiza Laboratorike"}
                />
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="Testimi-i-Paketimit"
                checked={selectedCategory.includes("Testimi i Paketimit")}
                onChange={() => handleCategorySelect("Testimi i Paketimit")}
              />
              <label htmlFor="Testimi-i-Paketimit">
                <span className="bottomIcon bottom__icon8"></span>
                <FormattedMessage
                  id="BottomEducation__9"
                  defaultMessage={"Testimi i Paketimit"}
                />
              </label>
            </li>
          </ul>
        </div>



        <div className="bottomEducation__left__options bottomEducation__left__4">
          <h2 onClick={() => setOpenReviews(!openReviews)} className='leftBottom__produktetMake20'>
            <FormattedMessage
              id="BottomEducation__18"
              defaultMessage={"Komente"}
            />
          </h2>

          <ul className={`mobileEducation ${openReviews ? "hide" : "show"}`}>
            <li>
              <input
                type="checkbox"
                id="5Star"
                checked={selectStar.includes("5Star")}
                onChange={() => handleStarSelect("5Star")}
              />
              <label htmlFor="5Star">
                5<span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="4Star"
                checked={selectStar.includes("4Star")}
                onChange={() => handleStarSelect("4Star")}
              />
              <label htmlFor="4Star">
                4<span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
                <span className="grayStar"></span>
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="3Star"
                checked={selectStar.includes("3Star")}
                onChange={() => handleStarSelect("3Star")}
              />
              <label htmlFor="3Star">
                3<span className="star"></span>
                <span className="star"></span>
                <span className="star"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="2Star"
                checked={selectStar.includes("2Star")}
                onChange={() => handleStarSelect("2Star")}
              />
              <label htmlFor="2Star">
                2<span className="star"></span>
                <span className="star"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
              </label>
            </li>

            <li>
              <input
                type="checkbox"
                id="1Star"
                checked={selectStar.includes("1Star")}
                onChange={() => handleStarSelect("1Star")}
              />
              <label htmlFor="1Star">
                1<span className="star"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
                <span className="grayStar"></span>
              </label>
            </li>
          </ul>
        </div>
      </div>

      <div className="bottomEducation__right">
        <div className="bottomEducation__right__top">
          <div className="left">
              <div className="exploreAndResults">
                <h1>
                  <FormattedMessage
                    id="BottomEducation__19"
                    defaultMessage={"Eksploroni"}
                  />
                </h1>
              </div>
          </div>

          <div className="right">
            <p>
              <FormattedMessage
                id="BottomEducation__21"
                defaultMessage={"Shikoni:"}
              />
            </p>



            <p className="pBorder perPageMain">
              {perPageNumber}
              <FormattedMessage
                id="BottomEducation__22"
                defaultMessage={" për faqe "}
              />
              <IoIosArrowDown />

              <div className="perPage">
                <p onClick={() => setPerPageNumber(9)}>
                  9
                  <FormattedMessage
                    id="BottomEducation__22"
                    defaultMessage={" për faqe "}
                  />
                </p>

                <p onClick={() => setPerPageNumber(18)}>
                  18
                  <FormattedMessage
                    id="BottomEducation__22"
                    defaultMessage={" për faqe "}
                  />
                </p>

                <p onClick={() => setPerPageNumber(28)}>
                  28
                  <FormattedMessage
                    id="BottomEducation__22"
                    defaultMessage={" për faqe "}
                  />
                </p>
              </div>
            </p>

            <p className="pBorder perPageMain perPageMain2">
              <BiMenuAltLeft />
              <FormattedMessage
                id="BottomEducation__253"
                defaultMessage={sortOrder}
              />
              <IoIosArrowDown />

              <div className="perPage perPageSort">
                <p
                  className=" inRightpBorder insideP"
                  onClick={() => handleSort("name")}
                >
                  <span className={`${sortOrder ? "backColor" : ""}`}>
                    DESC
                  </span>
                  <span className={`${sortOrder ? "backColor" : ""}`}>ASC</span>
                </p>

                {/* <p className='pBorder inRightpBorder' onClick={() => handleSort('star')}>
                        {sortOrder === 'asc' ? 'High to Low Rating' : 'Low to High Rating'}
                        <IoIosArrowDown />
                        </p> */}
              </div>
            </p>

            <p className="pBorder inRightpBorder inRightpBorder2"  onClick={handleGrid}>
              <BsFillGrid3X3GapFill className={`${inGrid ? "" : "orange"} `} />
              <span className="line"></span>
              <FaList className={`${inGrid ? "orange" : ""} `} />
            </p>
          </div>
        </div>

        {
        (filteredAndPaginatedData.filter((item) => (
          item.productTitle.toLowerCase().includes(searchTerm.toLowerCase())
        )).length)
        ||
        filteredAndPaginatedData.length > 0
        ||
        filteredData.length > 0
        ? (
          <div className={`bottomEducation__right__mid ${inGrid ? "makeInLine" : ""}`}>
            {filteredAndPaginatedData.map((item) => (
              item.productTitle.toLowerCase().includes(searchTerm.toLowerCase()) && (
                <Link
                  to={{
                    pathname: `/produkte/${item.id}/${item.productTitle.replace(/ /g, "_")}`,
                    state: { ProduktetENdryshmeData: item },
                  }}
                  key={item.id}
                >
                    <ProduktetENdryshmeCard 
                        key={item.id}
                        productImg={`${process.env.REACT_APP_PRODUKTETENDRYSHME_IMAGE}${item.productImage}`}
                        zbritje={item.zbritje}
                        orangeName={item.orangeName}
                        dataEPostimit={item.dataEPostimit}
                        certifikimiNumber={item.certifikimiNumber}
                        certifikimi={item.certifikimi}
                        productTitle={item.productTitle}
                        vlersimi={item.vlersimi}
                    />
                </Link>
              )
            ))}

          </div>
         ) : ( 
           <p className="noResults">No Results found</p> 
         )} 


        {
          !filteredData.length
          ?
          filteredData.length
          :
          (filteredAndPaginatedData.filter((item) => (
            item.productTitle.toLowerCase().includes(searchTerm.toLowerCase())
          )).length)
        > 0 ? (
          <div className="bottomEducation__right__bottom">
            <ul className="bottom__numbers">
              <p onClick={prePage}>
                <MdKeyboardArrowLeft />
                <FormattedMessage
                  id="BottomEducation__24"
                  defaultMessage={"Previous"}
                />
              </p>

              {currentPage > numbersToShow && (
                <li>
                  <Link
                    to="#"
                    onClick={() => changeCPage(1)}
                    className="page-link"
                  >
                    1
                  </Link>
                </li>
              )}
              {currentPage > numbersToShow  && (
                <li>
                  <span>...</span>
                </li>
              )}

              {getPageNumbers().map((n) => (
                <li
                  key={n}
                  className={`page-item ${
                    currentPage === n ? "activeBottom" : ""
                  }`}
                >
                  <Link
                    to="#"
                    onClick={() => changeCPage(n)}
                    className="page-link"
                  >
                    {n}
                  </Link>
                </li>
              ))}

              {currentPage < totalPages - 2 && (
                <li>
                  <span>...</span>
                </li>
              )}
              {currentPage < totalPages - 2 && (
                <li>
                  <Link
                    to="#"
                    onClick={() => changeCPage(totalPages)}
                    className="page-link"
                  >
                    {totalPages}
                  </Link>
                </li>
              )}

              <p onClick={nextPage}>
                <FormattedMessage
                  id="BottomEducation__25"
                  defaultMessage={"Next"}
                />
                <MdKeyboardArrowRight />
              </p>
            </ul>
          </div>
        ) : (
          <></>
        )}
      </div>

    </div>
  )
}

export default BottomProduktet