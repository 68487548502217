import React from 'react'
import './TestimiIPaketimit.scss'
import { RiArrowRightSLine } from 'react-icons/ri'
import { FiPhone } from 'react-icons/fi'
import { MdOutlineEmail } from 'react-icons/md'
import { Link } from 'react-router-dom'

import image1 from '../../assets/TestimePage/TestimiIUshqimeve/card1.webp'
import image2 from '../../assets/TestimePage/TestimiIUshqimeve/card2.webp'
import image3 from '../../assets/TestimePage/TestimiIUshqimeve/card3.webp'
import image4 from '../../assets/TestimePage/TestimiIUshqimeve/card4.webp'
import image5 from '../../assets/TestimePage/TestimiIUshqimeve/card5.webp'
import image6 from '../../assets/TestimePage/TestimiIUshqimeve/card6.webp'
import image7 from '../../assets/TestimePage/TestimiIUshqimeve/card7.webp'
import image8 from '../../assets/TestimePage/TestimiIUshqimeve/card8.webp'
import image9 from '../../assets/TestimePage/TestimiIUshqimeve/card9.webp'
import image10 from '../../assets/TestimePage/TestimiIUshqimeve/card10.webp'

const TestimiIPaketimit = () => {
    const testimIUshqimeveData1 = [
        {
            id: 1,
            text: "Konsultimi rregullator",
            imageTestimi: image1
        },

        {
            id: 2,
            text: "Vlerësimet e rrezikut",
            imageTestimi: image2
        },

        {
            id: 3,
            text: "Kërkesat e etiketimit",
            imageTestimi: image3
        },

        {
            id: 4,
            text: "Dizajni i paketimit dhe optimizimi i dizajnit",
            imageTestimi: image4
        }
    ]

    const testimIUshqimeveData2 = [
        {
            id: 5,
            text: "Shërbimet e testimit të kontaktit me ushqimin",
            imageTestimi: image5
        },

        {
            id: 6,
            text: "Testimi i performancës",
            imageTestimi: image6
        },

        {
            id: 7,
            text: "Analiza e dështimit",
            imageTestimi: image7
        },

        {
            id: 8,
            text: "Karakterizimi i materialit",
            imageTestimi: image8
        },

        {
            id: 9,
            text: "Shërbimet e testimit të produkteve të letrës",
            imageTestimi: image8
        },

        {
            id: 10,
            text: "Analiza kimike e materialeve",
            imageTestimi: image8
        }
    ]

    return (
        <div className='TestimiIPaketimit'>
            <div className="TestimiIUshqimeve__1">
                <div className="TestimiIUshqimeve__filter"></div>
                <div className="TestimiIUshqimeve__1__insider">
                    <div className="left">
                        <p className='make50font'>
                            Testimi i Paketimit
                        </p>

                        <p className='regular18'>
                            Paketimi i sigurt i produktit
                        </p>
                    </div>
                </div>
            </div>

            <div className="TestimiIUshqimeve__2">
                <p className="medium16">
                    Shërbimet
                </p>
                <RiArrowRightSLine />
                <p className="medium16 changeColorP">
                    Testimi i Paketimit
                </p>
            </div>

            <div className="TestimiIUshqimeve__bottom">
                <div className="left">



                    <div className="left__1">
                        <div className="left__titleAndP">
                            <p className="make50">
                                Sigurimi
                            </p>

                            <p className="regular20 makeHeight">
                                Rrjeti ynë global i ekspertëve rregullatorë i ndihmon klientët tanë të lundrojnë
                                produktet e tyre përmes fushës së gjerë të rregulloreve dhe standardeve në
                                mbarë botën; ne ofrojmë besimin dhe sigurinë e nevojshme për të
                                minimizuar rrezikun dhe për të sjellë me efikasitet zgjidhjet e paketimit
                                në juridiksionet e dëshiruara.
                            </p>
                        </div>

                        <div className="testimiCards">
                            {
                                testimIUshqimeveData1.map((item) => (
                                    <div className="testimiCard" key={item.id}>
                                        <p className="testimiCard__text ">
                                            {item.text}
                                        </p>

                                        <div className="testimiCard__img" style={{
                                            backgroundImage: `url(${item.imageTestimi})`
                                        }}></div>
                                    </div>
                                ))
                            }
                            <div className="circleAbsolute"></div>
                        </div>
                    </div>


                    <div className="left__1">
                        <div className="left__titleAndP">
                            <p className="make50">
                                Testimi dhe Analiza
                            </p>

                            <p className="regular20 makeHeight">
                                Testing and analysis is an integral step in the development of packaging design,
                                production and use. Intertek will perform testing to industry standards such as
                                International Safe Transit Association (ISTA), as well as custom protocols.
                                In addition to the physical testing of a package TESTKOS offers a wide range
                                of analytical services such as:
                            </p>
                        </div>

                        <div className="testimiCards">
                            {
                                testimIUshqimeveData2.map((item) => (
                                    <div className="testimiCard" key={item.id}>
                                        <p className="testimiCard__text ">
                                            {item.text}
                                        </p>

                                        <div className="testimiCard__img" style={{
                                            backgroundImage: `url(${item.imageTestimi})`
                                        }}></div>
                                    </div>
                                ))
                            }
                            <div className="circleAbsolute"></div>
                            <div className="circleAbsolute2"></div>
                        </div>
                    </div>

                </div>

                <div className="right">

                    <div className="right__1">
                        <p className="medium20">
                            Keni nevojë për ndihmë apo keni një pyetje?
                        </p>

                        <p className='regular18 pWithSvg'>
                            <FiPhone />
                            Na Kontaktoni
                        </p>

                        <p className='regular18 pWithSvg'>
                            <MdOutlineEmail />
                            Na shkruani ne Email
                        </p>

                        <Link className='medium18'>
                            Na dërgoni një kërkesë
                        </Link>
                    </div>

                    <p className="faqeTeNgjashme">
                        Faqe të ngjashme
                    </p>

                    <div className="right__2">
                        <Link className="medium20">
                            Sigurimi
                        </Link>

                        <Link className="medium20">
                            Testimi & Analiza
                        </Link>

                        <Link className="medium20">
                            Shërbimet e Inspektimit
                        </Link>

                        <Link className="medium20">
                            Auditimi dhe Certifikimi
                        </Link>

                        <Link className="medium20">
                            Zgjidhjet e industrisë
                        </Link>
                    </div>

                    <div className="right__3">
                        <p className="medium20">
                            Vendndodhjet e Laboratorit të Testimit të Ushqimit
                        </p>

                        <Link className='medium18'>
                            Shiko
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TestimiIPaketimit