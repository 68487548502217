import React from "react";
import "./ReagimiNeKohe.scss";
import Btn from "../../shared/Btn/Btn";
import { IoPlay } from "react-icons/io5";

const ReagimiNeKohe = () => {
  return (
    <div className="ReagimiNeKohe">
      <div className="left">
        <span className="regular15">Për ne</span>

        <h1 className="medium36">
          Reagime në kohë reale. Nga klientët e vërtetë. Kudo që punoni.
        </h1>

        <p className="regular20">
          Kështu që ju mund të krijoni përvoja që marrin rezultate reale.
          Testime dhe Çertifikime.
        </p>

        <Btn textBtn={"mËso mË shumË"} />
      </div>

      <div className="right">
        <div className="ReagimiNeKohe__image"></div>

        <div className="ReagimiNeKohe__circle__1"></div>
        <div className="ReagimiNeKohe__circle__2">
          <IoPlay />
        </div>
        <div className="ReagimiNeKohe__circle__3"></div>
      </div>
    </div>
  );
};

export default ReagimiNeKohe;
