import React from 'react'
import './Btn.scss'

const Btn = ({textBtn}) => {
  return (
    <div className='Btn'>
        {textBtn}
    </div>
  )
}

export default Btn