import React from 'react'
import './VecoritTonaCard.scss'

const VecoritTonaCard = ({ vecoritIcon, vecoritTitle, vecoritDsc }) => {
    const vecoritIconDiv = {
        content: `url( ${vecoritIcon})`
    }

  return (
    <div className='VecoritTonaCard'> 
        <div className="VecoritTonaCard__top">
            <div className="left" style={vecoritIconDiv}></div>

            <p className="medium25">
            {vecoritTitle}
            </p>
        </div>
        

        <div className="VecoritTonaCard__border"></div>

        <p className="regular19 VecoritTonaCard__p">
        {vecoritDsc}
        </p>
    </div>
  )
}

export default VecoritTonaCard