import React from "react";
import "./KeniNevojePerKonsulte.scss";

const KeniNevojePerKonsulte = () => {
  return (
    <div className="KeniNevojePerKonsulte">
      <p className="medium32">Keni nevojë për një konsultë?</p>

      <div className="KeniNevojePerKonsulte__content">
        <div className="left">
          <p className="regular17">
            Plotësoni formularin dhe ne do t'ju kthehemi brenda 24 orësh.
          </p>

          <form>
            <div className="inRow">
              <div className="withLabel">
                <label htmlFor="emri" className="regular16">
                  Emri-Mbiemri
                </label>
                <div className="withInput">
                  <input type="text" />
                  <div className="iconInside iconInside1"></div>
                </div>
              </div>

              <div className="withLabel">
                <label htmlFor="emriKompanise" className="regular16">
                  Emri I Kompanisë
                </label>
                <div className="withInput">
                  <input type="text" />
                  <div className="iconInside iconInside2"></div>
                </div>
              </div>
            </div>

            <div className="inRow">
              <div className="withLabel">
                <label htmlFor="emri" className="regular16">
                  Emaili Juaj
                </label>
                <div className="withInput">
                  <input type="email" />
                  <div className="iconInside iconInside3"></div>
                </div>
              </div>

              <div className="withLabel">
                <label htmlFor="emri" className="regular16">
                  Numri I Telefonit Të Kompanisë
                </label>
                <div className="withInput">
                  <input type="number" />
                  <div className="iconInside iconInside4"></div>
                </div>
              </div>
            </div>

            <div className="withLabel withLabelTextarea">
              <label htmlFor="emri" className="regular16">
                Si Mund Të Ju Ndihmojmë
              </label>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </div>
          </form>

          <button className="regular16">Dergo mesazh</button>
        </div>

        <div className="right">
          <div className="keniNevoje__card">
            <div className="keniNevoj__img">
              <div className="keniNevoj__icon keniNevoj__icon1"></div>
            </div>

            <div className="keniNevoj__texts">
              <p className="regular20">
                Përgatitni një vlerësim dhe na dërgoni
              </p>

              <p className="regular14">
                Përfshirë kronologjinë, metodologjinë dhe kostot.
              </p>
            </div>
          </div>

          <div className="keniNevoje__card">
            <div className="keniNevoj__img">
              <div className="keniNevoj__icon keniNevoj__icon2"></div>
            </div>

            <div className="keniNevoj__texts">
              <p className="regular20">Merrni këshillat tona falas</p>

              <p className="regular14">
                Pasi të kemi analizuar platformat tuaja ekzistuese.
              </p>
            </div>
          </div>

          <div className="keniNevoje__card">
            <div className="keniNevoj__img">
              <div className="keniNevoj__icon keniNevoj__icon3"></div>
            </div>

            <div className="keniNevoj__texts">
              <p className="regular20">Na kontaktoni përmes emailit</p>

              <p className="regular14">info@testkos.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeniNevojePerKonsulte;
