import React from 'react'
import './TestimiIUshqimeveBIO.scss'
import { RiArrowRightSLine } from 'react-icons/ri'
import { FiPhone } from 'react-icons/fi'
import { MdOutlineEmail } from 'react-icons/md'
import { Link } from 'react-router-dom'

import image1 from '../../assets/TestimePage/TestimiIUshqimeveBIO/image1.webp'
import image2 from '../../assets/TestimePage/TestimiIUshqimeveBIO/image2.webp'
import image3 from '../../assets/TestimePage/TestimiIUshqimeveBIO/image3.webp'

const TestimiIUshqimeveBIO = () => {

    const testimIUshqimeveData1 = [
        {
            id: 1,
            text: "Identifikimi bruto i përbërësve të papërpunuar",
            imageTestimi: image1
        },

        {
            id: 2,
            text: "Identifikimi dhe përcaktimi sasior i përbërësve gjysmë deri në shumë të përpunuar",
            imageTestimi: image2
        },

        {
            id: 3,
            text: "Identifikimi konfirmues i përbërësve të përpunuar të përzier",
            imageTestimi: image3
        }
    ]

    return (
        <div className='TestimiIUshqimeveBIO'>
            <div className="TestimiIUshqimeve__1">
                <div className="TestimiIUshqimeve__filter"></div>
                <div className="TestimiIUshqimeve__1__insider">
                    <div className="left">
                        <p className='make50font'>
                            Testimi i Ushqimeve BIO
                        </p>

                        <p className='regular18'>
                            Identifikimi i përbërësve natyralë në ushqimet nutraceutike dhe funksionale
                        </p>
                    </div>


                </div>
            </div>

            <div className="TestimiIUshqimeve__2">
                <p className="medium16">
                    Shërbimet
                </p>
                <RiArrowRightSLine />
                <p className="medium16 changeColorP">
                    Testimi i Ushqimeve BIO
                </p>
            </div>

            <div className="TestimiIUshqimeve__bottom">
                <div className="left">



                    <div className="left__1">
                        <div className="left__titleAndP">
                            <p className="regular20 makeHeight">
                                Nga lëndët e para deri te ekstraktet shumë të përpunuara, TESTKOS ofron identifikim
                                të përbërësve botanik për një shumëllojshmëri të gjerë produktesh përmes
                                përdorimit të metodologjive aktuale të vërtetuara shkencërisht.
                            </p>

                            <p className="regular20 makeHeight">
                                Identifikimi i përbërësve natyralë në lëndët ushqyese dhe ushqimet funksionale
                                paraqet një sfidë unike për prodhuesit, duke pasur parasysh kompleksitetin e
                                materialeve origjinale botanike dhe përpunimin e përbërjes së përbërësve.
                                Për më tepër, si Administrata e Ushqimit dhe Barnave e SHBA (FDA) ashtu
                                edhe Akti i Shëndetit dhe Edukimit të Suplementeve Ushqimore (DSHEA)
                                kërkojnë që përbërësit ushqyes natyralë të identifikohen dhe të vërtetohen
                                për prodhimin dhe shpërndarjen e shtesave dietike.
                            </p>
                        </div>

                        <div className="testimiCards">
                            {
                                testimIUshqimeveData1.map((item) => (
                                    <div className="testimiCard" key={item.id}>
                                        <p className="testimiCard__text ">
                                            {item.text}
                                        </p>

                                        <div className="testimiCard__img" style={{
                                            backgroundImage: `url(${item.imageTestimi})`
                                        }}></div>
                                    </div>
                                ))
                            }
                            <div className="circleAbsolute"></div>
                            <div className="circleAbsolute2"></div>
                        </div>
                    </div>





                    <div className="left__1">
                        <div className="left__titleAndP">
                            <p className="semibold20Bio">
                                Për të mësuar më shumë, shkarkoni fletën tonë të të dhënave për
                                <span className="makeGreenBio">&nbsp;
                                    Testimin E Identifikimit Të Përbërësve Natyrorë Ose Broshurën E Shërbimeve Të Identifikimit Botanik.
                                </span>
                            </p>
                        </div>
                    </div>





                </div>

                <div className="right">

                    <div className="right__1">
                        <p className="medium20">
                            Keni nevojë për ndihmë apo keni një pyetje?
                        </p>

                        <p className='regular18 pWithSvg'>
                            <FiPhone />
                            Na Kontaktoni
                        </p>

                        <p className='regular18 pWithSvg'>
                            <MdOutlineEmail />
                            Na shkruani ne Email
                        </p>

                        <Link className='medium18'>
                            Na dërgoni një kërkesë
                        </Link>
                    </div>

                    <p className="faqeTeNgjashme">
                        Faqe të ngjashme
                    </p>

                    <div className="right__2">
                        <Link className="medium20">
                            Sigurimi
                        </Link>

                        <Link className="medium20">
                            Testimi & Analiza
                        </Link>

                        <Link className="medium20">
                            Shërbimet e Inspektimit
                        </Link>

                        <Link className="medium20">
                            Auditimi dhe Certifikimi
                        </Link>

                        <Link className="medium20">
                            Zgjidhjet e industrisë
                        </Link>
                    </div>

                    <div className="right__3">
                        <p className="medium20">
                            Vendndodhjet e Laboratorit të Testimit të Ushqimit
                        </p>

                        <Link className='medium18'>
                            Shiko
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestimiIUshqimeveBIO