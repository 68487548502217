import React from "react";
import "./SherbimetTonaCard.scss";
import { Link } from "react-router-dom";

const SherbimetTonaCard = ({ sherbimetIcon, sherbimetTitle, sherbimetDsc }) => {
  const contentDiv = {
    content: `url(${sherbimetIcon})`,
  };

  return (
    <div className="SherbimetTonaCard">
      <div className="SherbimetTonaCard__icon" style={contentDiv}></div>

      <p className="medium19">{sherbimetTitle}</p>

      <p className="makeGrayAtSherbimetTona medium16">{sherbimetDsc}</p>

      <Link className="medium16">Mëso më shumë</Link>
    </div>
  );
};

export default SherbimetTonaCard;
