import React from "react";
import "./ProduktetENdryshmeCard.scss";

const ProduktetENdryshmeCard = ({
  productImg,
  zbritje,
  orangeName,
  dataEPostimit,
  certifikimiNumber,
  certifikimi,
  productTitle,
  vlersimi,
}) => {

    const imageDiv = {
        backgroundImage: `url(${productImg})`
    }
    
  return (
    <div className="ProduktetENdryshmeCard">
      <div
        className="ProduktetENdryshmeCard__top"
        style={imageDiv}
      >
        {zbritje ? (
          <div className="ProduktetENdryshmeCard__action">
            {zbritje} % Zbritje
          </div>
        ) : null}
      </div>

      <div className="ProduktetENdryshmeCard__bottom">
        <div className="ProduktetENdryshmeCard__bottom__1">
          <p className="regular16">{orangeName}</p>

          <p className="regular15">{dataEPostimit}</p>
        </div>

        <p className="regular20 testimi__P">{productTitle}</p>

        <div className="ProduktetENdryshmeCard__bottom__2">
          <div className="iconCard2"></div>

          <p className="regular16">
            Çertifikimi:
            <span>{certifikimiNumber}</span>- {certifikimi}
          </p>
        </div>

        <div className="ProduktetENdryshmeCard__bottom__3">
          <div className="goldStar"></div>
          <div className="goldStar"></div>
          <div className="goldStar"></div>
          <div className="goldStar"></div>
          <div className="star"></div>

          <p className="regular15">Vlerësimet nga {vlersimi}</p>
        </div>
      </div>
    </div>
  );
};

export default ProduktetENdryshmeCard;
