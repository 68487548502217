import React, { useState } from "react";
import "./Regjistrohu.scss";
import PartneretTane from "../../components/Home/PartneretTane/PartneretTane";
import LeftTestim from "../../components/shared/LeftTestim/LeftTestim";
import { Link } from "react-router-dom";

const Regjistrohu = () => {
  const [klientVendor, setKlientVendor] = useState(false);

  const handleKlientVendor = () => {
    setKlientVendor(!klientVendor);
  };

  return (
    <div className="main__Regjistrohu">
      <div className="Regjistrohu">
        <div className="Regjistrohu__left">
          <div className="Regjistrohu__left__inside">
            <LeftTestim />

            <div className="Regjistrohu__left__inside__filter"></div>
          </div>
        </div>

        <div className="Regjistrohu__right">
          <div className="RegjistrohuIcon"></div>
          <div className="Regjistrohu__right__inside">
            <div className="Regjistrohu__right__1">
              <div className="left">
                <p className="reg__33">Regjistrohu</p>

                <div className="line"></div>
              </div>

              <Link to="/Login" className="medium15 kyqu">
                Kyqu
              </Link>
            </div>

            <div className="Regjistrohu__right__2">
              <button
                className={`medium15 ${klientVendor ? "" : "reg__activeBtn"}`}
                onClick={handleKlientVendor}
              >
                Klient Vendor
              </button>

              <button
                className={`medium15 ${klientVendor ? "reg__activeBtn" : ""}`}
                onClick={handleKlientVendor}
              >
                Organizat Vendore
              </button>
            </div>

            <div className="reg__inputAndLabel">
              <label htmlFor="name" className="regular16">
                Shkruani Emrin dhe Mbiemrin
              </label>
              <div className="inRowInputOnly">
                <div className="inputWithIcon">
                  <span className="smallIconInsideInput smallIconInsideInputUsername"></span>
                  <input
                    type="text"
                    name=""
                    id="name"
                    placeholder="Enter name"
                  />
                </div>

                <div className="inputWithIcon">
                  <span className="smallIconInsideInput smallIconInsideInputUsername"></span>
                  <input
                    type="text"
                    name=""
                    id="name2"
                    placeholder="Enter surname"
                  />
                </div>
              </div>
            </div>

            <div className="reg__inputAndLabel">
              <label htmlFor="email" className="regular16">
                Emaili
              </label>
              <div className="inputWithIcon">
                <span className="smallIconInsideInput smallIconInsideInputEmail"></span>
                <input
                  type="text"
                  name=""
                  id="email"
                  placeholder="Enter email"
                />
              </div>
            </div>

            <div className="reg__inputAndLabel">
              <label htmlFor="numriITelefonit" className="regular16">
                Numri i Telefonit
              </label>
              <div className="inputWithIcon">
                <span className="smallIconInsideInput smallIconInsideInputNumri"></span>
                <input
                  type="text"
                  name=""
                  id="numriITelefonit"
                  placeholder="Enter phone number"
                />
              </div>
            </div>

            <div className="reg__inputAndLabel">
              <label htmlFor="vendi" className="regular16">
                Vendi
              </label>
              <div className="inputWithIcon">
                <span className="smallIconInsideInput smallIconInsideInputVendi"></span>
                <input
                  type="text"
                  name=""
                  id="vendi"
                  placeholder="Enter country"
                />
              </div>
            </div>

            <div className="reg__inputAndLabel">
              <label htmlFor="password" className="regular16">
                Password
              </label>
              <div className="inputWithIcon">
                <span className="smallIconInsideInput smallIconInsideInputPassword"></span>
                <input
                  type="password"
                  name=""
                  id="password"
                  placeholder="Enter password"
                />
              </div>
            </div>

            <div className="withCheckbox">
              <input type="checkbox" id="forCheckbox" />
              <label htmlFor="forCheckbox" className="regular15">
                I Kam Lexuar Gjitha
                <span className="changeColor">&nbsp; Kushtet</span>
              </label>
            </div>

            <button className="regular16 regjistrohuBtn">Regjistrohu</button>
          </div>
        </div>
      </div>
      <PartneretTane />
    </div>
  );
};

export default Regjistrohu;
