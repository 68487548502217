import React from 'react'
import './MenyraMeEMire.scss'
import Btn from '../../shared/Btn/Btn'

const MenyraMeEMire = () => {
  return (
    <div className='MenyraMeEMire'>
        <div className="left">

            <div className="MenyraMeEMire__image"></div>
            <div className="MenyraMeEMire__bg__3"></div>
            <div className="MenyraMeEMire__circle__1"></div>
        </div>

        <div className="right">
            <span className='regular15'>
            per ne
            </span>

            <h1 className='medium36'>
            Mënyra më e mirë për të promovuar testuar & Çertifikuar.
            </h1>

            <p className="regular20">
            Testkos është platforma numër një në botë për testimin dhe çertifikimin, dhe besohet nga mijëra organizata. 
            </p>

            <Btn textBtn={"Shiko tek ne"}/>
        </div>
    </div>
  )
}

export default MenyraMeEMire