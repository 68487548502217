import React, { useEffect, useRef, useState } from "react";
import "./ProduktetENdryshme.scss";
import ProduktetENdryshmeCard from "./ProduktetENdryshmeCard/ProduktetENdryshmeCard";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Carousel from "react-elastic-carousel";
import axios from "axios";
import { Link } from "react-router-dom";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 150, itemsToShow: 1 },
  { width: 650, itemsToShow: 2 },
  { width: 1200, itemsToShow: 3 },
];

const ProduktetENdryshme = () => {
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [originalPageData, setOriginalPageData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [sortByLatest, setSortByLatest] = useState(true);

  const onNextStart = () => {
    const nextIndex = currentIndex + 1;
    setCurrentIndex(nextIndex);

    if (nextIndex === filteredData.length + 1) {
      setCurrentIndex(1);
    } else {
      carouselRef.current.goTo(nextIndex);
    }
  };

  const onPrevStart = () => {
    const newIndex = currentIndex - 1 >= 1 ? currentIndex - 1 : 1;
    setCurrentIndex(newIndex);

    carouselRef.current.goTo(newIndex);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("search", inputValue);
    window.history.pushState({ path: currentUrl.href }, "", currentUrl.href);
  };

  const handleSortByLatest = () => {
    setSortByLatest(true);
    sortData(true);

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("order", "latest");
    window.history.pushState({ path: currentUrl.href }, "", currentUrl.href);
  };

  const handleSortByFirst = () => {
    setSortByLatest(false);
    sortData(false);

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("order", "first");
    window.history.pushState({ path: currentUrl.href }, "", currentUrl.href);
  };

  const sortData = (latestFirst) => {
    const sortedData = [...pageData].sort((a, b) => {
      if (latestFirst) {
        return b.id - a.id;
      } else {
        return a.id - b.id;
      }
    });
    setPageData(sortedData);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}API/ProduktetENdryshme.json`)
      .then((res) => {
        setPageData(res.data);
        setOriginalPageData(res.data);
      })
      .catch((error) => {
        console.error("ProduktetENdryshme : ", error);
      });
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("order") || urlParams.has("search")) {
      urlParams.delete("order");
      urlParams.delete("search");
      const newUrl = `${window.location.origin}${window.location.pathname}`;
      window.history.replaceState({}, "", newUrl);
    }
  }, []);

  const filteredData = pageData.filter((item) =>
    item.productTitle.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <div className="ProduktetENdryshme">
      <p className="medium36">Produktet e Ndryshme</p>

      <div className="ProduktetENdryshme__1">
        <div className="inputDiv">
          <div className="inputIcon"></div>
          <input
            type="text"
            placeholder="Kërko diqka..."
            value={searchInput}
            onChange={handleInputChange}
          />
          <button>Kërko</button>
        </div>

        <div className="teFundit">
          <div className="teFundit__inside" onClick={handleSortByLatest}>
            <div className="iconTefundit"></div>
            <p>Të Fundit</p>
            <div className="arrowTefundit"></div>
          </div>

          <div
            className="teFundit__inside teFundit__inside2"
            onClick={handleSortByFirst}
          >
            <div className="iconTefundit"></div>
            <p>Të Parat</p>
            <div className="arrowTefundit"></div>
          </div>
        </div>

        <div className="produktet__filter">
          <div className="filter1">
            <div className="filter1Icon"></div>
          </div>

          <div className="filter2">
            <div className="filter2Icon"></div>
          </div>
        </div>
      </div>

      <div className="ProduktetENdryshme__2">
        <Carousel
          className="carouselInLexoniTeRejatTona slider styled-arrows"
          breakPoints={breakPoints}
          ref={carouselRef}
          disableArrowsOnEnd={false}
        >
          {filteredData.map((item) => (
 
            <Link
            to={{
              pathname: `/produkte/${item.id}/${item.productTitle.replace(/ /g, "_")}`,
              state: { ProduktetENdryshmeData: item },
            }}
            key={item.id}
          >
            
            <ProduktetENdryshmeCard
              key={item.id}
              productImg={`${process.env.REACT_APP_PRODUKTETENDRYSHME_IMAGE}${item.productImage}`}
              zbritje={item.zbritje}
              orangeName={item.orangeName}
              dataEPostimit={item.dataEPostimit}
              certifikimiNumber={item.certifikimiNumber}
              certifikimi={item.certifikimi}
              productTitle={item.productTitle}
              vlersimi={item.vlersimi}
            />
            </Link>
          ))}
        </Carousel>
      </div>

      <div className="ProduktetENdryshme__3">
        <MdKeyboardArrowLeft onClick={onPrevStart} className="left" />

        <div className="ProduktetENdryshme__3__insider">
          <div
            className={
              currentIndex === 1
                ? "numberProdukt numberProduktActive"
                : "numberProdukt"
            }
            onClick={() => setCurrentIndex(1)}
          >
            1
          </div>

          <div
            className={
              currentIndex >= 2 && currentIndex < filteredData.length
                ? "numberProdukt numberProduktActive"
                : "numberProdukt"
            }
          >
            {currentIndex >= 0 && currentIndex < filteredData.length
              ? currentIndex <= 1
                ? 2
                : currentIndex
              : "..."}
          </div>

          <div
            className={
              currentIndex == filteredData.length
                ? "numberProdukt numberProduktActive"
                : "numberProdukt"
            }
          >
            {currentIndex != filteredData.length ? (
              <>...</>
            ) : (
              filteredData.length
            )}
          </div>
        </div>

        <MdKeyboardArrowRight onClick={onNextStart} className="right" />
      </div>
    </div>
  );
};

export default ProduktetENdryshme;
