import React from 'react'
import './TestimiIUshqimeve.scss'
import { RiArrowRightSLine } from 'react-icons/ri'
import { FiPhone } from 'react-icons/fi'
import { MdOutlineEmail } from 'react-icons/md'
import { Link } from 'react-router-dom'

import image1 from '../../assets/TestimePage/TestimiIUshqimeve/card1.webp'
import image2 from '../../assets/TestimePage/TestimiIUshqimeve/card2.webp'
import image3 from '../../assets/TestimePage/TestimiIUshqimeve/card3.webp'
import image4 from '../../assets/TestimePage/TestimiIUshqimeve/card4.webp'
import image5 from '../../assets/TestimePage/TestimiIUshqimeve/card5.webp'
import image6 from '../../assets/TestimePage/TestimiIUshqimeve/card6.webp'
import image7 from '../../assets/TestimePage/TestimiIUshqimeve/card7.webp'
import image8 from '../../assets/TestimePage/TestimiIUshqimeve/card8.webp'
import image9 from '../../assets/TestimePage/TestimiIUshqimeve/card9.webp'
import image10 from '../../assets/TestimePage/TestimiIUshqimeve/card10.webp'
import image11 from '../../assets/TestimePage/TestimiIUshqimeve/card11.webp'
import image12 from '../../assets/TestimePage/TestimiIUshqimeve/card12.webp'

const TestimiIUshqimeve = () => {

    const testimIUshqimeveData1 = [
        {
            id: 1,
            text: "Testimi mikrobiologjik i ushqimit",
            imageTestimi: image1
        },

        {
            id: 2,
            text: "Analiza ndijore e ushqimit",
            imageTestimi: image2
        },

        {
            id: 3,
            text: "Testimi i alergjive ushqimore",
            imageTestimi: image3
        },

        {
            id: 4,
            text: "Mashtrimi me ushqimin",
            imageTestimi: image4
        }
    ]

    const testimIUshqimeveData2 = [
        {
            id: 5,
            text: "Analiza e mjaltit",
            imageTestimi: image5
        },

        {
            id: 6,
            text: "Zgjidhje për ushqim deti",
            imageTestimi: image6
        },

        {
            id: 7,
            text: "Zgjidhje ushqimore të përshtatshme",
            imageTestimi: image7
        },

        {
            id: 8,
            text: "Zgjidhje për Shitje me Pakicë dhe Mikpritje",
            imageTestimi: image8
        }
    ]

    const testimIUshqimeveData3 = [
        {
            id: 9,
            text: "Auditimi dhe Certifikimi i Sigurisë Ushqimore",
            imageTestimi: image9
        },

        {
            id: 10,
            text: "Gjurmueshmëria e ushqimit",
            imageTestimi: image10
        },

        {
            id: 11,
            text: "Zgjidhjet e etiketimit të ushqimit",
            imageTestimi: image11
        },

        {
            id: 12,
            text: "Sigurimi i njerëzve",
            imageTestimi: image12
        }
    ]

    return (
        <div className='TestimiIUshqimeve'>
            <div className="TestimiIUshqimeve__1">
                <div className="TestimiIUshqimeve__filter"></div>
                <div className="TestimiIUshqimeve__1__insider">
                    <div className="left">
                        <p className='make50font'>
                            Testimi i Ushqimeve
                        </p>

                        <p className='regular18'>
                            Eksploroni të gjitha detajet e shërbimeve
                        </p>
                    </div>

                    <div className="right regular20">
                        Mbroni markat dhe reputacionin tuaj me ne.
                    </div>
                </div>
            </div>

            <div className="TestimiIUshqimeve__2">
                <p className="medium16">
                    Shërbimet
                </p>
                <RiArrowRightSLine />
                <p className="medium16 changeColorP">
                    Testimi i Ushqimeve
                </p>
            </div>

            <div className="TestimiIUshqimeve__bottom">
                <div className="left">



                    <div className="left__1">
                        <div className="left__titleAndP">
                            <p className="make50">
                                Testimi & Analiza e Ushqimit
                            </p>

                            <p className="regular20 makeHeight">
                                Testimi i ushqimit është pjesë përbërëse e prodhimit efikas të
                                produkteve të sigurta dhe cilësore. Me industrinë ushqimore
                                që i nënshtrohet gjithnjë e më shumë shqyrtimit, testimi për të
                                siguruar përputhjen me rregulloret e sigurisë ushqimore
                                dhe për të mbrojtur shëndetin publik është një domosdoshmëri
                            </p>
                        </div>

                        <div className="testimiCards">
                            {
                                testimIUshqimeveData1.map((item) => (
                                    <div className="testimiCard" key={item.id}>
                                        <p className="testimiCard__text ">
                                            {item.text}
                                        </p>

                                        <div className="testimiCard__img" style={{
                                            backgroundImage: `url(${item.imageTestimi})`
                                        }}></div>
                                    </div>
                                ))
                            }
                            <div className="circleAbsolute"></div>
                        </div>
                    </div>


                    <div className="left__1">
                        <div className="left__titleAndP">
                            <p className="make50">
                                Produktet dhe Operacionet Ushqimore
                            </p>

                            <p className="regular20 makeHeight">
                                Specialistët tanë globalë me ekspertizë lokale punojnë me ju për
                                t'ju udhëhequr me gjërat që kanë rëndësi për biznesin tuaj.
                                Fusha jonë gjithëpërfshirëse e shërbimeve do të thotë se jemi
                                të specializuar në mbështetjen e një shumëllojshmërie të gjerë
                                të industrive brenda sektorit të ushqimit. Ekspertët tanë të
                                industrisë mbështesin organizatën tuaj pavarësisht se ku bëni
                                biznes brenda zinxhirit të furnizimit me ushqime.
                            </p>
                        </div>

                        <div className="testimiCards">
                            {
                                testimIUshqimeveData2.map((item) => (
                                    <div className="testimiCard" key={item.id}>
                                        <p className="testimiCard__text ">
                                            {item.text}
                                        </p>

                                        <div className="testimiCard__img" style={{
                                            backgroundImage: `url(${item.imageTestimi})`
                                        }}></div>
                                    </div>
                                ))
                            }
                            <div className="circleAbsolute"></div>
                        </div>
                    </div>


                    <div className="left__1">
                        <div className="left__titleAndP">
                            <p className="make50">
                                Zgjidhje të Sigurimit të Sigurisë Ushqimore
                            </p>

                            <p className="regular20 makeHeight">
                                Ekspertët e ushqimit të TestKos mund t'ju ndihmojnë të lundroni
                                në produktet tuaja përmes pasurive të rreziqeve, rregulloreve dhe
                                pasigurive të mundshme në mbarë botën. Ne ju ofrojmë besimin
                                dhe sigurinë që ju nevojitet për të ndihmuar në zhvillimin dhe
                                nxjerrjen e produkteve ushqimore në treg, për të zbutur rreziqet
                                dhe për ta mbajtur biznesin tuaj të funksionojë dhe të
                                përmirësohet vazhdimisht.
                            </p>
                        </div>

                        <div className="testimiCards">
                            {
                                testimIUshqimeveData3.map((item) => (
                                    <div className="testimiCard" key={item.id}>
                                        <p className="testimiCard__text ">
                                            {item.text}
                                        </p>

                                        <div className="testimiCard__img" style={{
                                            backgroundImage: `url(${item.imageTestimi})`
                                        }}></div>
                                    </div>
                                ))
                            }
                            <div className="circleAbsolute"></div>
                        </div>
                    </div>





                </div>

                <div className="right">

                    <div className="right__1">
                        <p className="medium20">
                            Keni nevojë për ndihmë apo keni një pyetje?
                        </p>

                        <p className='regular18 pWithSvg'>
                            <FiPhone />
                            Na Kontaktoni
                        </p>

                        <p className='regular18 pWithSvg'>
                            <MdOutlineEmail />
                            Na shkruani ne Email
                        </p>

                        <Link className='medium18'>
                            Na dërgoni një kërkesë
                        </Link>
                    </div>

                    <p className="faqeTeNgjashme">
                        Faqe të ngjashme
                    </p>

                    <div className="right__2">
                        <Link className="medium20">
                            Sigurimi
                        </Link>

                        <Link className="medium20">
                            Testimi & Analiza
                        </Link>

                        <Link className="medium20">
                            Shërbimet e Inspektimit
                        </Link>

                        <Link className="medium20">
                            Auditimi dhe Certifikimi
                        </Link>

                        <Link className="medium20">
                            Zgjidhjet e industrisë
                        </Link>
                    </div>

                    <div className="right__3">
                        <p className="medium20">
                            Vendndodhjet e Laboratorit të Testimit të Ushqimit
                        </p>

                        <Link className='medium18'>
                            Shiko
                        </Link>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default TestimiIUshqimeve