import React from "react";
import "./Testimit.scss";
import { Link } from "react-router-dom";
import Btn from "../../shared/Btn/Btn";
import { FaCirclePlay } from "react-icons/fa6";
import { IoPlay } from "react-icons/io5";

const Testimit = () => {
  return (
    <div className="Testimit">
      <div className="Testimit__left">
        <div className="testimetBgLeft">
          <p>TK</p>
        </div>

        <h1 className="medium43">
          Testim me besueshmëri dhe me cilësi të lartë përvoja unike dixhitale e
          <span className="colorSpan">&nbsp;testimit</span>
        </h1>

        <p className="regular20 pInTestimit">
          Testim i produkteve në të gjitha Industritë dhe kompanite Republikes
          së Kosovës.
        </p>

        <div className="testimit__btn">
          <Btn textBtn={"Filloni tani"} />

          <Btn textBtn={"NA KONTAKTONI"} />
        </div>
      </div>

      <div className="Testimit__right">
        <div className="circle">
          <IoPlay />
        </div>
      </div>
    </div>
  );
};

export default Testimit;
