import React from 'react'
import './Home.scss'
import Testimit from '../../components/Home/Testimit/Testimit'
import SherbimetTona from '../../components/Home/SherbimetTona/SherbimetTona'
import DeklarataDheTestimi from '../../components/Home/DeklarataDheTestimi/DeklarataDheTestimi'
import TrajnimCertifikim from '../../components/Home/TrajnimCertifikim/TrajnimCertifikim'
import UdhetimiYne from '../../components/Home/UdhetimiYne/UdhetimiYne'
import ReagimiNeKohe from '../../components/Home/ReagimiNeKohe/ReagimiNeKohe'
import MenyraMeEMire from '../../components/Home/MenyraMeEMire/MenyraMeEMire'
import PartneretTane from '../../components/Home/PartneretTane/PartneretTane'
import KeniNevojePerKonsulte from '../../components/Home/KeniNevojePerKonsulte/KeniNevojePerKonsulte'
import LexoniTeRejatTona from '../../components/Home/LexoniTeRejatTona/LexoniTeRejatTona'
import VecoritTona from '../../components/Home/VecoritTona/VecoritTona'
import EksploroniTeGjitha from '../../components/Home/EksploroniTeGjitha/EksploroniTeGjitha'
import ProduktetENdryshme from '../../components/Home/ProduktetENdryshme/ProduktetENdryshme'

const Home = (props) => {
  return (
    <div className='Home'>

      <Testimit />
      <SherbimetTona />
      <DeklarataDheTestimi />
      <TrajnimCertifikim />
      <ProduktetENdryshme />
      <VecoritTona />
      <UdhetimiYne />
      <EksploroniTeGjitha />
      <ReagimiNeKohe />

      <LexoniTeRejatTona /> 
      <MenyraMeEMire />
      <KeniNevojePerKonsulte />
      <PartneretTane />
        
    </div>
  )
}

export default Home