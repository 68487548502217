import React from "react";
import "./SherbimetTona.scss";
import "../../shared/Btn/Btn";
import Btn from "../../shared/Btn/Btn";
import SherbimetTonaCard from "../../shared/SherbimetTonaCard/SherbimetTonaCard";

import testimiProdukteve from "../../../assets/Sherbimet/testimiProdukteve.svg";
import testimIUshqimeve from "../../../assets/Sherbimet/testimIUshqimeve.svg";
import testimiIPijeve from "../../../assets/Sherbimet/testimiIPijeve.svg";
import testimiBio from "../../../assets/Sherbimet/analizaLaboratorike.svg";
import testimeLaboratorike from "../../../assets/Sherbimet/testimeLaboratorike.svg";
import testimiISigurise from "../../../assets/Sherbimet/testimiISigurise.svg";

const SherbimetTona = () => {
  const SherbimetData = [
    {
      id: 1,
      sherbimetIcon: testimiProdukteve,
      sherbimetTitle: "Testimi I Produkteve",
      sherbimetDsc:
        "Testimi I Produkteve te kompanisë Tuaj. Testimin e materialit te përdorimit, vlerësimin e produktit...",
    },

    {
      id: 2,
      sherbimetIcon: testimIUshqimeve,
      sherbimetTitle: "Testimi I Ushqimeve",
      sherbimetDsc:
        "Testimin e Ushqimeve llojet e perdorura te ushqimeve dhe kulaitetin e tyre...",
    },

    {
      id: 3,
      sherbimetIcon: testimiIPijeve,
      sherbimetTitle: "Testimi I Pijeve",
      sherbimetDsc:
        "Testimin e pijeve alkoholike dhe ato jo alkoholike. testimin e pijeve te gazuara permbajtaj e sheqerit etj...",
    },

    {
      id: 4,
      sherbimetIcon: testimiBio,
      sherbimetTitle: "Testimi I Ushqimeve Bio",
      sherbimetDsc:
        "Testimin e Ushqimeve biologjike që konsumohen në Kosovë, përmbajtjen e tyre..",
    },

    {
      id: 5,
      sherbimetIcon: testimeLaboratorike,
      sherbimetTitle: "Testime Laboratorike",
      sherbimetDsc:
        "Në laboratorin tonë testimet  bëhen me anë të pajisjeve  moderne, duke mundësuar cilësi e saktësi në rezultate...",
    },

    {
      id: 6,
      sherbimetIcon: testimiISigurise,
      sherbimetTitle: "Testimi I Sigurisë Së Produktit",
      sherbimetDsc:
        "Testet e sigurisë së produktit kanë të bëjnë me ofrimin e një produkti të sigurt, me cilësi dhe superiore për klientët Tuaj...",
    },
  ];

  return (
    <div className="SherbimetTona">
      <span className="regular15">qka ofrojm ne?</span>

      <p className="medium40">Shërbimet tona</p>

      <p className="make80InSherbimet regular19">
        TestKos ofron një sërë shërbimesh që mund t'ju ndihmojnë të arrini
        qëllimet e biznesit tuaj dhe të përmirësoni biznesin tuaj. E thjeshtë, e
        përshtatshme dhe e lirë. TestKos është më e mira.
      </p>

      <div className="sherbimetTonaCards">
        {SherbimetData.map((item) => (
          <SherbimetTonaCard
            key={item.id}
            sherbimetIcon={item.sherbimetIcon}
            sherbimetTitle={item.sherbimetTitle}
            sherbimetDsc={item.sherbimetDsc}
          />
        ))}
      </div>

      <Btn textBtn={"Gjitha shËrbimet"} />
    </div>
  );
};

export default SherbimetTona;
