import React from 'react'
import './UdhetimiYne.scss'
import Btn from '../../shared/Btn/Btn'

const UdhetimiYne = () => {
  return (
    <div className='UdhetimiYne'>
        
        <div className="left">

            <div className="UdhetimiYne__image"></div>
            <div className="UdhetimiYne__bg__3"></div>
            <div className="UdhetimiYne__circle__1"></div>
            <div className="UdhetimiYne__circle__2"></div>
        </div>

        <div className="right">
            <span className='regular15'>
            Rrreth nesh
            </span>

            <h1 className='medium36'>
            Udhëtimi ynë 
            </h1>

            <p className="regular20">
            Ne kemi punuar në një sërë industrive, të tilla si shitja me pakicë, shitja me shumicë, financat, media, OJQ / Jo-fitimprurëse, qeveria dhe zakonisht jemi të njohur me sfidat me të cilat përballen klientët tanë. Ne testojmë të gjitha gjërat që ju mendoni se nuk mund të rregullohen ose testohen. 
            </p>

            <Btn textBtn={"mËso mË shumË"}/>
        </div>


    </div>
  )
}

export default UdhetimiYne