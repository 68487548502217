import React, { useRef } from "react";
import "./PartneretTane.scss";
// import { Carousel } from 'react-responsive-carousel';
import Carousel from "react-elastic-carousel";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 350, itemsToShow: 2 },
  { width: 650, itemsToShow: 4 },
  { width: 1200, itemsToShow: 6 },
];

const PartneretTane = () => {
  const carouselRef = useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo("single-slide".length);
    }
  };

  return (
    <div className="PartneretTane">
      <p className="medium40">Partnerët Tanë</p>

      <Carousel
        className="carouselInPartner slider styled-arrows"
        breakPoints={breakPoints}
        ref={carouselRef}
        onPrevStart={onPrevStart}
        onNextStart={onNextStart}
        disableArrowsOnEnd={false}
      >
        <div className="partnertTaneIcon partnertTaneIcon1"></div>
        <div className="partnertTaneIcon partnertTaneIcon2"></div>
        <div className="partnertTaneIcon partnertTaneIcon3"></div>
        <div className="partnertTaneIcon partnertTaneIcon4"></div>
        <div className="partnertTaneIcon partnertTaneIcon5"></div>
        <div className="partnertTaneIcon partnertTaneIcon6"></div>
        <div className="partnertTaneIcon partnertTaneIcon1"></div>
        <div className="partnertTaneIcon partnertTaneIcon2"></div>
        <div className="partnertTaneIcon partnertTaneIcon3"></div>
        <div className="partnertTaneIcon partnertTaneIcon4"></div>
        <div className="partnertTaneIcon partnertTaneIcon5"></div>
        <div className="partnertTaneIcon partnertTaneIcon6"></div>
      </Carousel>
    </div>
  );
};

export default PartneretTane;
