import React, { useState } from "react";
import "./MobileNavbar.scss";
import { Link } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import SelectLanguage from "../SelectLanguage/SelectLangugage";
import { MdKeyboardArrowDown } from "react-icons/md";
import ColorSwitch from '../ColorSwitch/ColorSwitch'

const MobileNavbar = () => {
  const [openRrethNesh, setOpenRrethNesh] = useState(false);
  const [openSherbimet, setOpenSherbimet] = useState(false);

  const handleRrethNesh = () => {
    setOpenRrethNesh(!openRrethNesh);
  };

  const handleSherbimet = () => {
    setOpenSherbimet(!openSherbimet);
  };

  return (
    <div className="MobileNavbar">
      <div className="lineInMobile"></div>

      <div className="mobileNavItems">
        <div
          className={`navItem mobileNavbar__with__dropdown ${
            openRrethNesh ? "removePaddingFromNav" : ""
          }`}
        >
          <Link className={`regular19 ${openRrethNesh ? "makeBoldInMobileNav" : ""}`} onClick={handleRrethNesh}>
            Rreth Nesh
            {openRrethNesh ? (
              <MdKeyboardArrowDown className="rotateSVG" />
            ) : (
              <MdKeyboardArrowDown />
            )}
          </Link>

          <div
            className={`dropDownMobile ${
              openRrethNesh ? "displayDropDown" : ""
            }`}
          >
            <Link className="regular19">
              Për ne
              <span className="smallSpanInDropDown">Rreth Testkos</span>
            </Link>

            <Link className="regular19">
              Të Rejat
              <span className="smallSpanInDropDown">Lexoni më të rejat</span>
            </Link>

            <Link className="regular19">
              Termat
              <span className="smallSpanInDropDown">
                Termat dhe kondicionet
              </span>
            </Link>

            <Link className="regular19">
              Ekpia Jonë
              <span className="smallSpanInDropDown">Ekpia e Testkos</span>
            </Link>

            <Link className="regular19">
              Privatesia
              <span className="smallSpanInDropDown">
                Privatesia e prodhuesit
              </span>
            </Link>

            <Link className="regular19">
              FAQ
              <span className="smallSpanInDropDown">
                Pyetjet & përgjigjet
              </span>
            </Link>

            <Link className="regular19">
              Mbështetja
              <span className="smallSpanInDropDown">Mbështetja jonë</span>
            </Link>

            <Link className="regular19">
              Partnerët
              <span className="smallSpanInDropDown">Partnerët tanë</span>
            </Link>
          </div>
        </div>

        <div
          className={`navItem mobileNavbar__with__dropdown ${
            openSherbimet ? "removePaddingFromNav" : ""
          }`}
        >
          <Link className={`regular19 ${openSherbimet ? "makeBoldInMobileNav" : ""}`} onClick={handleSherbimet}>
            Shërbimet
            {openSherbimet ? (
              <MdKeyboardArrowDown className="rotateSVG" />
            ) : (
              <MdKeyboardArrowDown />
            )}
          </Link>

          <div
            className={`dropDownMobile ${
              openSherbimet ? "displayDropDown" : ""
            }`}
          >
            <Link className="regular19">
              Testimi I Produkteve
              <span className="smallSpanInDropDown">
                Testimi lloj lloj produkteve
              </span>
            </Link>

            <Link className="regular19">
              Testime Laboratorike
              <span className="smallSpanInDropDown">Testet laboratorike</span>
            </Link>

            <Link to="/TestimiIUshqimeve" className="regular19">
              Testimi I Ushqimeve
              <span className="smallSpanInDropDown">Testet e ushqimeve</span>
            </Link>

            <Link className="regular19">
              Testimi I Sigurisë së Produktit
              <span className="smallSpanInDropDown">Siguria e produktit</span>
            </Link>

            <Link to="/TestimiIPijeve" className="regular19">
              Testimi I Pijeve
              <span className="smallSpanInDropDown">
                Pijet alkoholike dhe jo alkoholike
              </span>
            </Link>

            <Link className="regular19">
              Analiza Laboratorike
              <span className="smallSpanInDropDown">
                Analizat laboratorike
              </span>
            </Link>

            <Link to="/TestimiIUshqimeveBIO" className="regular19">
              Testimi I Ushqimeve BIO
              <span className="smallSpanInDropDown">
                Testimi i prodhimeve BIO
              </span>
            </Link>

            <Link to="/TestimiIPaketimit" className="regular19">
              Testimi I Paketimit
              <span className="smallSpanInDropDown">
                Paketimi i sigurt i produktit
              </span>
            </Link>
          </div>
        </div>

        <div className="navItem ">
          <Link className="regular19">Resurset</Link>
        </div>

        <div className="navItem ">
          <Link className="regular19">Karriera</Link>
        </div>

        <div className="navItem ">
          <Link className="regular19">Blog</Link>
        </div>

        <div className="navItem ">
          <Link className="regular19">Kontakti</Link>
        </div>
      </div>

      <div className="moveToBottom">
        <div className="midNavbar">
          <ThemeSwitcher />
          <SelectLanguage />
        </div>

        <div className="centerColor">
        <ColorSwitch />
        </div>

        <div className="mobileNav__bottom">
          <div className="navbar__search">
            <CiSearch />
            <input type="text" placeholder="Kërko" className="regular16" />
          </div>

          <div className="mobileNav__buttons">
            <Link to="/Login" className="kycu">Kyçu</Link>

            <Link to="/Regjistrohu" className="regjistrohu">Regjistrohu</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
