import React from "react";
import "./TestimiIPijeve.scss";
import { RiArrowRightSLine } from "react-icons/ri";
import { FiPhone } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";
import { Link } from "react-router-dom";

import image1 from "../../assets/TestimePage/TestimiIUshqimeve/card1.webp";
import image2 from "../../assets/TestimePage/TestimiIUshqimeve/card2.webp";

const TestimiIPijeve = () => {
  const testimIUshqimeveData1 = [
    {
      id: 1,
      text: "Testimi mikrobiologjik i ushqimit",
      imageTestimi: image1,
    },

    {
      id: 2,
      text: "Analiza ndijore e ushqimit",
      imageTestimi: image2,
    },
  ];

  return (
    <div className="TestimiIPijeve">
      <div className="TestimiIUshqimeve__1">
        <div className="TestimiIUshqimeve__filter"></div>
        <div className="TestimiIUshqimeve__1__insider">
          <div className="left">
            <p className="make50font">Testimi i Pijeve</p>

            <p className="regular18">
              Eksploroni të gjitha detajet e shërbimeve
            </p>
          </div>
        </div>
      </div>

      <div className="TestimiIUshqimeve__2">
        <p className="medium16">Shërbimet</p>
        <RiArrowRightSLine />
        <p className="medium16 changeColorP">Testimi i Pijeve</p>
      </div>

      <div className="TestimiIUshqimeve__bottom">
        <div className="left">
          <div className="left__1">
            <div className="left__titleAndP">
              <p className="make50">
                Çfarë Është Sigurimi i Cilësisë së Pijeve?
              </p>

              <p className="regular20 makeHeight">
                Sigurimi i cilësisë së pijeve përfshin testimin e pranisë së
                lëndëve ushqyese në ujë dhe auditimin e procesit të prodhimit,
                midis aktiviteteve të tjera. Një pije zakonisht përbëhet nga më
                shumë se 85% ujë dhe përbërësit e mbetur jo-ujorë përfshijnë
                përbërës si sheqeri, aromatizuesit, ëmbëlsuesit, ngjyruesit etj.
                Kontrolli i cilësisë në përpunimin e pijeve është thelbësor për
                të zbuluar ndotjen e ujit që mund të lindë jo vetëm nga ndotja e
                brendshme sistemet e tubacioneve të ujit por edhe nëpërmjet
                ndotjes së mjedisit që mund të ketë burim të kontaminuar uji.
              </p>
            </div>

            <div className="testimiCards">
              {testimIUshqimeveData1.map((item) => (
                <div className="testimiCard" key={item.id}>
                  <p className="testimiCard__text ">{item.text}</p>

                  <div
                    className="testimiCard__img"
                    style={{
                      backgroundImage: `url(${item.imageTestimi})`,
                    }}
                  ></div>
                </div>
              ))}
              <div className="circleAbsolute"></div>
            </div>
          </div>

          <div className="left__1">
            <div className="left__titleAndP">
              <p className="make50">
                Pse Është i Rëndësishëm Testimi i Pijeve?
              </p>

              <p className="regular20 makeHeight">
                Faktorë të ndryshëm, si temperatura, prania e lëndëve ushqyese
                në ujë që mund të çojnë në rritjen e mikroorganizmave si E.
                coli. dhe salmonela në pije, prania e ndotësve në ujë dhe
                përbërësit e ushqimit, si përdorimi i disa aditivëve dhe
                konservantëve, mund të ndikojnë në shijen dhe cilësinë e pijes
                së pijeve. Prandaj, qasja e rreptë e testimit për ujin dhe
                përbërësit janë hapat e parë drejt kontrollit të cilësisë së
                pijeve. Për më tepër, pijet janë subjekt i shqyrtimit nga
                agjencitë rregullatore globale. Zbatimi i teknikave të
                kontrollit të cilësisë përmes testimit, inspektimit dhe
                auditimit mund të ndihmojë njësitë e prodhimit të pijeve në
                përputhje me rregulloret si dhe të përmirësojnë pranueshmërinë e
                produktit të tyre nga konsumatorët.
              </p>
            </div>
          </div>
        </div>

        <div className="right">
          <div className="right__1">
            <p className="medium20">
              Keni nevojë për ndihmë apo keni një pyetje?
            </p>

            <p className="regular18 pWithSvg">
              <FiPhone />
              Na Kontaktoni
            </p>

            <p className="regular18 pWithSvg">
              <MdOutlineEmail />
              Na shkruani ne Email
            </p>

            <Link className="medium18">Na dërgoni një kërkesë</Link>
          </div>

          <p className="faqeTeNgjashme">Faqe të ngjashme</p>

          <div className="right__2">
            <Link className="medium20">Sigurimi</Link>

            <Link className="medium20">Testimi & Analiza</Link>

            <Link className="medium20">Shërbimet e Inspektimit</Link>

            <Link className="medium20">Auditimi dhe Certifikimi</Link>

            <Link className="medium20">Zgjidhjet e industrisë</Link>
          </div>

          <div className="right__3">
            <p className="medium20">
              Vendndodhjet e Laboratorit të Testimit të Ushqimit
            </p>

            <Link className="medium18">Shiko</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimiIPijeve;
