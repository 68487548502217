import React from "react";
import "./DropDownCard.scss";
import { Link } from "react-router-dom";

const DropDownCard = ({ icon, dataTitle, dataDsc, toWhere }) => {
  const bgLeftIcon = {
    content: `url(${icon})`,
  };
  return (
    <Link to={`${toWhere}`} className="DropDownCard">
      <div className="left">
        <div className="leftIcon" style={bgLeftIcon}></div>
      </div>

      <div className="right">
        <p className="medium16">{dataTitle}</p>

        <p className="regular14">{dataDsc}</p>
      </div>
    </Link>
  );
};

export default DropDownCard;
