import React from "react";
import "./VecoritTona.scss";
import VecoritTonaCard from "./VecoritTonaCard/VecoritTonaCard";

import icon1 from '../../../assets/VecoritTona/icon1.svg'
import icon2 from '../../../assets/VecoritTona/icon2.svg'
import icon3 from '../../../assets/VecoritTona/icon3.svg'
import icon4 from '../../../assets/VecoritTona/icon4.svg'
import icon5 from '../../../assets/VecoritTona/icon5.svg'
import icon6 from '../../../assets/VecoritTona/icon6.svg'

const VecoritTona = () => {
  
  
  
  const VecoritTonaData = [
    {
      id: 1,
      vecoritIcon: icon1,
      vecoritTitle: "Plotësisht I përshtatshëm",
      vecoritDsc: "Organizata juaj ka nevoja unike për testim dhe çertifikim. Ne ju japim kontroll të plotë për të ndërtuar një zgjidhje që është e përshtatshme për ju."
    },

    {
      id: 2,
      vecoritIcon: icon2,
      vecoritTitle: "Siguria e Avancuar",
      vecoritDsc: "Si një platformë testimi dhe çertifikimi e gatshme për ndërmarrje, ka një siguri të përparuar për të mbajtur të sigurt organizatën tuaj. "
    },

    {
      id: 3,
      vecoritIcon: icon3,
      vecoritTitle: "Zgjidhje të shkallëzuara",
      vecoritDsc: "TestKos u ndërtua për të përmbushur nevojat e korporatave të mëdha shumëkombëshe dhe organizatave të vogla të çdo madhësie në Kosovë. "
    },

    {
      id: 4,
      vecoritIcon: icon4,
      vecoritTitle: "Përkthimin e Produkteve",
      vecoritDsc: "Duke pasur parasysh shkallëzimin dhe gjithëpërfshirjen, TestKos përkthehet lehtësisht në 22 gjuhë për t'u testuar në të gjithë globin. "
    },

    {
      id: 5,
      vecoritIcon: icon5,
      vecoritTitle: "Shërbimet e Testime Tregtare",
      vecoritDsc: "Shumë nga klientët tanë tarifojnë për programet e testimit dhe çertifikimit. TestKos e bën atë shumë të lehtë me karakteristikat e shërbimeve tona tregtare."
    },

    {
      id: 6,
      vecoritIcon: icon6,
      vecoritTitle: "Protokoli i Testimit të integruar",
      vecoritDsc: "Për testimin e produkteve të larta dhe integritetin shtesë të tyre, TestKos integron me shërbimet virtuale të proktorizmit si dhe me proktorizimin tradicional. "
    },
  ]

  return (
    <div className="VecoritTona">
      <p className="medium34 VecoritTonaP">
        Kompleti ynë i veçorive në nivelin e ndërmarrjes është plotësisht i
        përshtatshëm, duke e bërë softuerin tonë të provimit në internet një
        zgjidhje perfekte të testimit për çdo produkt.
      </p>

      <div className="VecoritTona__cards">

        {
          VecoritTonaData.map((item) => (
            <VecoritTonaCard 
              key={item.id}
              vecoritIcon={item.vecoritIcon}
              vecoritTitle={item.vecoritTitle}
              vecoritDsc={item.vecoritDsc}
            />
          ))
        }
      </div>
    </div>
  );
};

export default VecoritTona;
