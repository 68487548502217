import React, { useState } from "react";
import "./EksploroniTeGjitha.scss";

import icon1 from "../../../assets/EksploroniTeGjitha/icon1.svg";
import icon2 from "../../../assets/EksploroniTeGjitha/icon2.svg";
import icon3 from "../../../assets/EksploroniTeGjitha/icon3.svg";
import icon4 from "../../../assets/EksploroniTeGjitha/icon4.svg";
import icon5 from "../../../assets/EksploroniTeGjitha/icon5.svg";
import icon6 from "../../../assets/EksploroniTeGjitha/icon6.svg";
import icon7 from "../../../assets/EksploroniTeGjitha/icon7.svg";
import icon8 from "../../../assets/EksploroniTeGjitha/icon8.svg";
import { Link } from "react-router-dom";
import { BsArrowRightCircle } from "react-icons/bs";

const EksploroniTeGjithaData = [
  {
    id: 1,
    eksploroniIcon: icon1,
    eksploroniTitle: "Produkte Bio",
    eksploroniSmallDsc: "Bio Testimi përfshin:",
    eksploroniDsc:
      "Ushqimet organike dallohet për të tilla nga mënyra se si një produkt ose ushqim është rritur dhe përpunuar. Pasi kërkesa dhe prodhimi i produkteve organike në tregun Evropian është rritur, Bashkimi Evropian ka rritur përpjekjet e tij për të zbatuar politika, standard dhe monitorim në këtë drejtim. Prodhimi organik është një sistem i përgjithshëm i menaxhimit të blegtorisë dhe prodhimit të ushqimeve që përdor procese ku mjedisi, shëndeti njerëzor, bimët dhe mirëqenia e kafshëve janë prioritare dhe të mbrojtura në mënyrë aktive.",
    eksploroniCard1: "Prodhimi të respektojë natyrën.",
    eksploroniCard2: "Produktet të prodhohen në mënyrë të qëndrueshme.",
    eksploroniCard3:
      "Kafshët e fermave të kullosin në mënyrë të lirë në ajër të pastër dhe të trajtohen ",
  },
  {
    id: 2,
    eksploroniIcon: icon2,
    eksploroniTitle: "Testimi I Pijeve",
    eksploroniSmallDsc: "Testimi i pijeve përfshin",
    eksploroniDsc:
      "Testimi i pijeve është një proces i cili përfshin vlerësimin dhe analizën e pijeve për cilësinë, sigurinë dhe shijeën e tyre. Kjo përfshin testimin për parametrat fizikë, kimikë, dhe mikrobiologjikë për të siguruar që pijeve të jenë të sigurta për konsum. Në këtë proces, janë të përfshira teste për nivelet e alkoolit (nëse aplikohet), përmbajtjen e sheqerit, prishtinat, dhe ndonjë lloj kontaminimi të mundshëm.",
    eksploroniCard1: "Vlerësimi i cilësisë së pijeve.",
    eksploroniCard2: "Analiza e sigurisë së pijeve për konsum.",
    eksploroniCard3:
      "Përcaktimi i shijes së pijeve dhe komentet e konsumatorëve.",
  },

  {
    id: 3,
    eksploroniIcon: icon3,
    eksploroniTitle: "Produkte Familjare",
    eksploroniSmallDsc: "Produkte familjare përfshin",
    eksploroniDsc:
      "Produktet familjare janë produkte që janë përdorur tradicionalisht në familje për shërbimin e tyre të përditshëm. Këto produkte shpesh janë të lidhura me kujtesën emocionale dhe traditat kulturore të një familjeje. Mund të përfshijë ushqime të veçanta të përgatitura me receta të trashëguara, artizanat të përdorura në përditshmëri, ose pajisje dhe pajisje të tjera shtëpiake që janë të rëndësishme për funksionimin e një familjeje.",
    eksploroniCard1: "Kujtesa emocionale dhe tradita kulturore.",
    eksploroniCard2: "Produkte të përditshme dhe të përshtatshme për familjen.",
    eksploroniCard3:
      "Artikuj dhe pajisje shtëpiake të rëndësishme për funksionimin e një familjeje.",
  },

  {
    id: 4,
    eksploroniIcon: icon4,
    eksploroniTitle: "Testime Laboratorike",
    eksploroniSmallDsc: "Testime laboratorike përfshin",
    eksploroniDsc:
      "Testimet laboratorike janë procese ku përdoren metoda dhe teknika të specializuara për të kryer analiza dhe vlerësime të ndryshme për produkte të ndryshme. Këto testime përfshijnë vlerësimin e cilësisë, sigurisë, përshtatshmërisë, dhe performancës së produkteve në laborator. Përdorimi i testimeve laboratorike është i rëndësishëm për të siguruar që produktet janë të sigurta për konsumatorët dhe për të zbatuar standardet e nevojshme të cilësisë dhe sigurisë.",
    eksploroniCard1: "Vlerësimi i cilësisë së produkteve në laborator.",
    eksploroniCard2: "Analiza e sigurisë dhe performancës së produkteve.",
    eksploroniCard3: "Vlerësimi i përshtatshmërisë së produkteve për përdorim.",
  },

  {
    id: 5,
    eksploroniIcon: icon5,
    eksploroniTitle: "Testimi I Ushqimeve",
    eksploroniSmallDsc: "Testime i ushqimeve përfshin",
    eksploroniDsc:
      "Testimi i ushqimeve është një proces i cili përfshin vlerësimin dhe analizën e cilësisë, sigurisë, dhe përshtatshmërisë së ushqimeve për konsum. Ky proces përdor metoda dhe teknika të specializuara për të identifikuar cilësinë dhe sigurinë e ushqimeve, duke përfshirë teste për parametrat kimikë, fizikë, dhe mikrobiologjikë. Testimi i ushqimeve është i rëndësishëm për të siguruar që ushqimet që konsumojmë janë të sigurta dhe cilësore.",
    eksploroniCard1: "Vlerësimi i cilësisë së ushqimeve.",
    eksploroniCard2: "Analiza e sigurisë së ushqimeve për konsum.",
    eksploroniCard3:
      "Vlerësimi i përshtatshmërisë së ushqimeve për tregun dhe konsumatorët.",
  },

  {
    id: 6,
    eksploroniIcon: icon6,
    eksploroniTitle: "Çertifikime",
    eksploroniSmallDsc: "Çertifikime përfshin",
    eksploroniDsc:
      "Çertifikimet për ushqimet organike janë vërtetime zyrtare që dëshmojnë se një produkt është prodhuar dhe/ose përpunuar sipas standardeve organike të përcaktuara nga autoritetet kompetente. Këto çertifikime mund të jepen nga organizata të besueshme të certifikimit që kanë përvojë në verifikimin e proceseve organike dhe që sigurojnë përmbushjen e kritereve të caktuara për produktet organike. Çertifikimi organik vërteton që një produkt është i prodhuar në mënyrë të natyrshme dhe pa përdorur pesticide apo gjëra të tjera të dëmshme për mjedisin dhe shëndetin e konsumatorit.",
    eksploroniCard1: "Vërtetimi i prodhimit organik.",
    eksploroniCard2: "Sigurimi i përmbushjes së standardeve organike.",
    eksploroniCard3:
      "Garancia për konsumatorët për cilësinë dhe origjinën organike të produktit.",
  },

  {
    id: 7,
    eksploroniIcon: icon7,
    eksploroniTitle: "Rezultate & Raportimi",
    eksploroniSmallDsc: "Rezultate & Raportimi përfshin",
    eksploroniDsc:
      "Rezultatet dhe raportimi për ushqimet organike përfshijnë dokumentimin dhe komunikimin e informacionit të lidhur me cilësinë, sigurinë dhe performancën e ushqimeve organike. Ky proces përfshin vlerësimin dhe dokumentimin e rezultateve të testeve, analizave dhe vlerësimeve të kryera për të siguruar që konsumatorët kanë akses në informacion të saktë dhe transparent për cilësinë dhe origjinën e ushqimeve të tyre. Raportimi i rezultateve është i rëndësishëm për të monitoruar dhe përmirësuar cilësinë dhe sigurinë e ushqimeve organike.",
    eksploroniCard1: "Dokumentimi i rezultateve të testeve dhe analizave.",
    eksploroniCard2:
      "Komunikimi i informacionit transparent për cilësinë dhe origjinën e ushqimeve.",
    eksploroniCard3:
      "Monitorimi dhe përmirësimi i cilësisë dhe sigurisë së ushqimeve organike.",
  },

  {
    id: 8,
    eksploroniIcon: icon8,
    eksploroniTitle: "Mbeshtjetja Teknike E Testimit",
    eksploroniSmallDsc: "Mbeshtetja teknike e testimit përfshin",
    eksploroniDsc:
      "Mbeshtetja teknike e testimit për ushqimet organike përfshin ofrimin e ekspertizës dhe këshillave teknike për të ndihmuar në realizimin e testeve dhe analizave të nevojshme për sigurimin e cilësisë dhe sigurisë së ushqimeve organike. Ky lloj mbeshtetje siguron që operacionet e prodhimit dhe testimi përmbushin standardet e nevojshme dhe kryejnë proceset me efikasitet dhe saktësi të maksimizuar. Mbeshtetja teknike është e rëndësishme për të siguruar që teste dhe analiza janë të kryera në mënyrë të saktë dhe të besueshme.",
    eksploroniCard1:
      "Ofrimi i ekspertizës dhe këshillave teknike për teste dhe analiza.",
    eksploroniCard2:
      "Sigurimi që operacionet përmbushin standardet e nevojshme për cilësi dhe siguri.",
    eksploroniCard3:
      "Përmirësimi i efikasitetit dhe saktësisë së proceseve të testimit dhe analizës.",
  },
];

const EksploroniTeGjitha = () => {
  const [clickedCard, setClickedCard] = useState(
    EksploroniTeGjithaData.find((item) => item.id === 1)
  );
  const [clickedCardId, setClickedCardId] = useState(1);

  const handleCardClick = (id) => {
    const clickedItem = EksploroniTeGjithaData.find((item) => item.id === id);
    setClickedCard(clickedItem);
    setClickedCardId(id);
  };

  return (
    <div className="EksploroniTeGjitha">
      <p className="medium40 EksploroniTeGjitha__p">
        Eksploroni të gjitha shërbimet e testimeve që ne ofrojmë
      </p>

      <div className="EksploroniTeGjitha__inside">
        <div className="EksploroniTeGjitha__inside__left">
          {EksploroniTeGjithaData.map((item) => (
            <div
              className={`exploroniCard ${
                clickedCardId === item.id ? "exploroniCardClick" : ""
              }`}
              key={item.id}
              onClick={() => handleCardClick(item.id)}
            >
              <div
                className="icon"
                style={{ content: `url(${item.eksploroniIcon})` }}
              ></div>
              <p className="medium17">{item.eksploroniTitle}</p>
            </div>
          ))}
          <Link className="eksploroniLink">
            Shikoni Gjitha Shërbimet
            <BsArrowRightCircle />
          </Link>
        </div>

        <div className="EksploroniTeGjitha__inside__right">
          {clickedCard && (
            <>
              <p className="medium25">{clickedCard.eksploroniTitle}</p>
              <p className="regular17 Eksploroni__p">
                {clickedCard.eksploroniDsc}
              </p>
              <p className="regular19">{clickedCard.eksploroniSmallDsc}</p>

              <div className="eksploroniCards">
                <div className="cardRight">
                  <div className="cardRight1"></div>
                  <p className="medium16">{clickedCard.eksploroniCard1}</p>
                </div>
                <div className="cardRight">
                  <div className="cardRight2"></div>
                  <p className="medium16">{clickedCard.eksploroniCard2}</p>
                </div>
                <div className="cardRight">
                  <div className="cardRight3"></div>
                  <p className="medium16">{clickedCard.eksploroniCard3}</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EksploroniTeGjitha;
