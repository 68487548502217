import React from 'react'
import './DeklarataDheTestimi.scss'
import Btn from '../../shared/Btn/Btn'

const DeklarataDheTestimi = () => {
  return (
    <div className='DeklarataDheTestimi'>
        <div className="left">
            <span className='regular15'>
            Rreth nesh
            </span>

            <h1 className='medium36'>
            Deklarata dhe testimi i produkteve në testKos. Më mirë dhe sigurt 
            </h1>

            <p className="regular20">
            Ne mbledhim së bashku ekspertizën e thellë të industrisë dhe avancimet më të fundit të IT për të provuar produkte të personalizuara që përshtaten në mënyrë të përsosur me nevojat dhe sjelljen e përdoruesve të tyre. Shërbimet tona evoluojnë vazhdimisht, në përputhje me teknologjinë, në mënyrë që ne t'i shërbejmë qëllimit tonë të testimit.
            </p>

            <Btn textBtn={"mËso mË shumË"}/>
        </div>

        <div className="right">

            <div className="deklarata__image"></div>

            <div className="deklarata__circle__1"></div>
            <div className="deklarata__circle__2"></div>
            <div className="deklarata__circle__3"></div>
            <div className="deklarata__circle__4"></div>
        </div>
    </div>
  )
}

export default DeklarataDheTestimi