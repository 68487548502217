import React from "react";
import "./Login.scss";
import LeftTestim from "../../components/shared/LeftTestim/LeftTestim";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <div className="Login">
      <div className="Login__filter1"></div>
      <div className="Login__filter2"></div>

      <div className="Login__left">
        <div className="Login__left__inside">
          <div className="Login__left__filter">
            <div className="filterText">TK</div>
            <LeftTestim />
          </div>
        </div>
      </div>

      <div className="Login__right">
        <div className="Login__right__inside">
          <div className="Login__right__1">
            <div className="left">
              <p className="reg__33">
                Kyçu
                <span className="regular15">në TESTKOS</span>
              </p>

              <div className="line"></div>
            </div>

            <Link to="/Regjistrohu" className="medium15 kyqu">
              Regjistrohu
            </Link>
          </div>

          <div className="reg__inputAndLabel">
            <label htmlFor="email" className="regular16">
              Emaili
            </label>
            <div className="inputWithIcon">
              <span className="smallIconInsideInput smallIconInsideInputEmail"></span>
              <input type="text" name="" id="email" placeholder="Enter email" />
            </div>
          </div>

          <div className="reg__inputAndLabel">
            <label htmlFor="password" className="regular16">
              Password
            </label>
            <div className="inputWithIcon">
              <span className="smallIconInsideInput smallIconInsideInputPassword"></span>
              <input
                type="password"
                name=""
                id="password"
                placeholder="Enter password"
              />
            </div>
          </div>

          <div className="mbajMendDheHarruat">
            <div className="withCheckbox">
              <input type="checkbox" id="forCheckbox" />
              <label htmlFor="forCheckbox" className="regular15">
                Mbaj mend
              </label>
            </div>

            <Link className="regular15">Harruat paswordin?</Link>
          </div>

          <button className="regular16 loginBtn">Kyçu</button>

          <p className="medium15 insidePneedToChangeAColor">
            Nuk jeni të regjistruar?&nbsp;
            <Link to="/Regjistrohu">Regjistrohuni tani.</Link>
          </p>

          <span className="makeGrayColor">
            @2021 All Right Reserved. Testkos Cookie Preferences, Privacy &
            Terms.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
