import React from 'react'
import './TrajnimCertifikim.scss'
import Btn from '../../shared/Btn/Btn'


const TrajnimCertifikim = () => {
  return (
    <div className='TrajnimCertifikim'>


        <div className="left">

            <div className="TrajnimCertifikim__image"></div>

            <div className="TrajnimCertifikim__circle__1"></div>
            <div className="TrajnimCertifikim__circle__2"></div>
            <div className="TrajnimCertifikim__circle__3"></div>
        </div>

        <div className="right">
            <h1 className='medium36'>
            Trajnim, testim dhe çertifikime më të mira - për të gjithë. 
            </h1>

            <p className="regular20">
            TESTKOS është platforma më e plotë e testimit në treg. Perfectshtë e përkryer për organizata të çdo madhësie, qofshin të gjitha në një vend apo të shpërndara në të gjithë globin. 
            </p>

            <Btn textBtn={"mËso mË shumË"}/>
        </div>
    </div>
  )
}

export default TrajnimCertifikim