import React, { useState } from 'react'
import './TestimiProduktevePage.scss'
import { Link } from 'react-router-dom'
import BottomProduktet from '../../components/TestimiProduktevePage/BottomProduktet/BottomProduktet'

const TestimiProduktevePage = () => {
    const [pageData, setPageData] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
  
    // useEffect(() => {
    //   axios
    //     .get("../../API/ProduktetENdryshme/ProduktetENdryshme.json")
    //     .then((res) => {
    //         setPageData(res.data);
    //     })
    //     .catch((error) => {
    //         console.error("ProduktetENdryshme : ", error);
    //   });
    // }, []);
  
    // const filteredData = pageData.filter((item) =>
    //   item.name.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    const filteredData = []
    

  return (
    <div className='TestimiProduktevePage'>

        <div className="TestimiProduktevePage__top">
            <div className="TopEducationFilter"></div>

                <div className="insideTopEducation">
                    <h1>
                    Detajet e Produkteve
                    </h1>

                    <p>
                    Eksploroni të gjitha detajet e produkteve
                    </p>

                    <div className="topEducation__search">
                      <div className="insideTopEducation__filter"></div>
                      <span className='search'></span>
                      <input 
                      type="search" 
                      placeholder='Kërko sipas fjalëve kyçe...'
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}  
                      />
                      <span className="icon2Filter"></span>
                    </div>
                    
            {searchTerm && (
                <div className="filteredData">
                {filteredData.map((item) => (
                    <Link
                    key={item.id}
                    to={{
                      pathname: `/ProduktetENdryshme/${item.id}/${item.name.replace(/ /g, "_")}`,
                      state: { produktetData: item } 
                    }}
                    >
                    <h2>{item.name}</h2>
                    </Link>
                ))}
                </div>
            )}

            </div>
        </div>

        <BottomProduktet />

    </div>
  )
}

export default TestimiProduktevePage