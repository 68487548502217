import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import SelectLanguage from "../SelectLanguage/SelectLangugage";
import ColorSwitch from '../../../components/shared/ColorSwitch/ColorSwitch'

const Footer = (props) => {
  return (
    <div className="Footer">
      <div className="Footer__1">
        <div className="Footer__1__filter"></div>
        <div className="Footer__1__texts">
          <div className="makeWhite__Footer__1">
          <p className="regular40">Na Ndiqni Për Më Të Rejat</p>

            <p className="regular20">
              Na Përcjellni për ofertat tona më të mira të Testimieve dhe
              Çertifikimeve
            </p>
          </div>

          <div className="footer1__email">
            <input
              type="text"
              placeholder="Shkruani E-mailin tuaj"
              className="regular15"
            />
            <button className="medium16">Na Përcjellni</button>
          </div>
        </div>
      </div>

      <div className="Footer__2">
        <div className="Footer__2__filter"></div>
        <div className="Footer__2__shade"></div>

        <div className="Footer__2__inside">
          <div className="Footer__2__section__1">
            <div className="left">
              <div className="whiteLogo"></div>

              <p className="medium18">
                Mënyra më e mirë për të provuar dhe vërtetuar.
              </p>
            </div>

            <div className="right">
              <div className="appStore"></div>
              <div className="googlePlay"></div>
            </div>
          </div>

          <div className="Footer__2__section__2">
            <div className="section__2__items">
              <p>TESTKOS</p>

              <div className="section__2">
                <Link className="medium17">Rreth Nesh</Link>

                <Link className="medium17">Ekpia Jone</Link>

                <Link className="medium17">Klientet Tanë</Link>

                <Link className="medium17">Karriera</Link>

                <Link className="medium17">Partnerët</Link>

                <Link className="medium17">Mbështetja</Link>

                <Link className="medium17">FAQ</Link>

                <Link className="medium17">Termat</Link>

                <Link className="medium17">Privatesia</Link>
              </div>
            </div>

            <div className="section__2__items">
              <p>Gjenerata e ardhshme e testimit</p>

              <div className="section__2">
                <Link className="medium17">Testimi Artificial</Link>

                <Link className="medium17">Testimi Permes Internetit</Link>

                <Link className="medium17">Testimi Direkt</Link>

                <Link className="medium17">Testimi I Performances</Link>

                <Link className="medium17">Testimi I Sigurimit</Link>

                <Link className="medium17">Testimi Automatik</Link>

                <Link className="medium17">Testimi Permes Maknieris</Link>

                <Link className="medium17">Testimi Mekanik</Link>

                <Link className="medium17">Testimi Mobil</Link>
              </div>
            </div>

            <div className="section__2__items">
              <p>shËrbimet</p>

              <div className="section__2">
                <Link className="medium17">All Services</Link>

                <Link className="medium17">Testimi I Produkteve</Link>

                <Link className="medium17">Testimi Familjar</Link>

                <Link className="medium17">Analiza Laboratorike</Link>

                <Link  to="/TestimiIPijeve"  className="medium17">Testimi I Pijeve</Link>

                <Link to="/TestimiIUshqimeve" className="medium17">Testimi I Ushqimeve</Link>

                <Link className="medium17">Çertifikimi</Link>

                <Link className="medium17">Testimi I Kualitetit</Link>

                <Link className="medium17">Verifikimi</Link>
              </div>
            </div>

            <div className="section__2__items">
              <p>resurset</p>

              <div className="section__2">
                <Link className="medium17">Na Kontaktoni</Link>

                <Link className="medium17">Lajmet</Link>

                <Link className="medium17">Testet</Link>

                <Link className="medium17">Qendra E Ndihmës</Link>

                <Link className="medium17">Blogu</Link>

                <Link className="medium17">Storiet E Suksesit</Link>

                <Link className="medium17">Pergjegjesia</Link>

                <Link className="medium17">Pyetjet</Link>

                <Link className="medium17">Certifikime Shtese</Link>
              </div>
            </div>

            <div className="section__2__items">
              <p>Industries</p>

              <div className="section__2">
                <Link className="medium17">Prodhuese</Link>

                <Link className="medium17">Blegtoriale</Link>

                <Link className="medium17">Kompanit Private</Link>

                <Link className="medium17">Bujqesia</Link>

                <Link className="medium17">Tregu</Link>

                <Link className="medium17">Produktet Biologjike</Link>

                <Link className="medium17">Agrokultura</Link>

                <Link className="medium17">Agrobizneset</Link>

                <Link className="medium17">Industrite E Ndryshme</Link>
              </div>
            </div>
          </div>

          <div className="Footer__2__section__3">
            <div className="left">
              <div className="footer__2__left">
                <Link className="socialItem">
                  <div className="socialItem__1"></div>
                </Link>

                <Link className="socialItem">
                  <div className="socialItem__2"></div>
                </Link>

                <Link className="socialItem">
                  <div className="socialItem__3"></div>
                </Link>

                <Link className="socialItem">
                  <div className="socialItem__4"></div>
                </Link>

                <Link className="socialItem">
                  <div className="socialItem__5"></div>
                </Link>

                <Link className="socialItem">
                  <div className="socialItem__6"></div>
                </Link>
              </div>

              <p>© 2021 TESTKOS. All rights reserved.</p>
            </div>

            <div className="right">
              <div className="top__right">
                <p>Mos Humb Asgjë !</p>

                <div className="mosHumbInput">
                  <div className="emailIcon__footer"></div>

                  <input type="text" placeholder="shkruaj emailin tuaj" />

                  <button className="naPercjell">Na Përcjell</button>
                </div>
              </div>

              <div className="bottom__right"> 
                <div className="top">
                  <ColorSwitch />

                </div>

               <div className="bottom">
               <ThemeSwitcher />

<p>
  <div className="emailIcon__footer"></div>
  kontakti@testkos.com
</p>

<SelectLanguage
  setLanguage={props.setLanguage}
  language={props.language}
/>
               </div>

                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
