import React from "react";
import "./LeftTestim.scss";

const LeftTestim = () => {
  return (
    <div className="LeftTestim">
      <div className="LeftTestim__1">
        <p className="with23">TESTIM & Çertifikim</p>

        <p className="medium19">Benifitet Shtesë vetëm në TestKos</p>
      </div>

      <div className="LeftTestim__card">
        <div className="left left1Icon1"></div>

        <p className="medium19">Plotësisht I Përshtatshëm</p>
      </div>

      <div className="LeftTestim__card">
        <div className="left left1Icon2"></div>

        <p className="medium19">Siguria E Avancuar</p>
      </div>

      <div className="LeftTestim__card">
        <div className="left left1Icon3"></div>

        <p className="medium19">Zgjidhje Të Shkallëzuara Dhe Testime Shtesë</p>
      </div>

      <div className="LeftTestim__card">
        <div className="left left1Icon4"></div>

        <p className="medium19">Protokoli I Testimit Të Integruar</p>
      </div>
    </div>
  );
};

export default LeftTestim;
