import React from "react";
import "./KontaktoniEkipinTone.scss";

const KontaktoniEkipinTone = () => {
  return (
    <div className="KontaktoniEkipinTone">
      <div className="KontaktoniEkipinTone__1">
        <div className="KontaktoniEkipinTone__1__filter"></div>
        <div className="KontaktoniEkipinTone__2__filter"></div>

        <div className="KontaktoniEkipinTone__1__inside">
          <p className="semibold60__kontaktoni">Kontaktoni ekipin tonë</p>

          <p className="regular20">
            Nëse keni nevojë për ndihmën tonë, keni pyetje se si të përdorni
            platformën ose jeni duke përjetuar probleme teknike vështirësi, ju
            lutemi mos hezitoni të na kontaktoni.
          </p>
        </div>
      </div>

      <div className="KontaktoniEkipinTone__2">
        <div className="twoInRow">
          <div className="labelAndInput">
            <label htmlFor="yourname">Your name*</label>
            <input type="text" id="yourname" placeholder="John Dee" />
          </div>

          <div className="labelAndInput">
            <label htmlFor="contactEmail">Contact email *</label>
            <input
              type="text"
              id="contactEmail"
              placeholder="you@example.com"
            />
          </div>
        </div>

        <div className="twoInRow">
          <div className="labelAndInput">
            <label htmlFor="companyName">Company name*</label>
            <input type="text" id="companyName" placeholder="Company name" />
          </div>

          <div className="labelAndInput">
            <label htmlFor="country">Country*</label>
            <select name="" id="country">
              <option value="Kosovo">Kosovo</option>
              <option value="Albanian">Albanian</option>
              <option value="Switzerland">Switzerland</option>
              <option value="Germany">Germany</option>
            </select>
          </div>
        </div>

        <div className="labelAndTextarea">
          <label htmlFor="yourname">Your message*</label>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            placeholder="Type your message…."
          ></textarea>
        </div>

        <p className="regular16 pAtKontakti">
          Duke dorëzuar këtë formular, ju pranoni termat dhe kushtet tona dhe
          Politikën tonë të Privatësisë, e cila shpjegon se si ne mund të
          mbledhim, përdorim dhe zbulojmë informacionin tuaj personal, duke
          përfshirë palët e treta.
        </p>

        <button>Dërgo</button>
      </div>

      <div className="KontaktoniEkipinTone__3">
        <div className="KontaktoniEkipinTone__card">
          <div className="bgCardIcon">
            <div className="insiderCardIcon insiderCardIcon1"></div>
          </div>

          <p className="medium25">Na dërgoni me email</p>

          <p className="regular16 makeSmallGap">
            Na dërgoni email për pyetje të përgjithshme, duke përfshirë
            mundësitë e marketingut dhe partneritetit.
          </p>

          <p className="kontaktoni16">info@testkos.com</p>
        </div>

        <div className="KontaktoniEkipinTone__card">
          <div className="bgCardIcon">
            <div className="insiderCardIcon insiderCardIcon2"></div>
          </div>

          <p className="medium25">Na telefononi</p>

          <p className="regular16 makeSmallGap">
            Na telefononi për të folur me një anëtar të ekipit tonë. Ne jemi
            gjithmonë të lumtur të ndihmojnë.
          </p>

          <p className="kontaktoni16">+383 00 000 000</p>
        </div>

        <div className="KontaktoniEkipinTone__card">
          <div className="bgCardIcon">
            <div className="insiderCardIcon insiderCardIcon3"></div>
          </div>

          <p className="medium25">Qendra e ndihmës</p>

          <p className="regular16 makeSmallGap">
            Shikoni burimet e dobishme, FAQ dhe mjetet e zhvilluesit.
          </p>

          <p className="kontaktoni16">Support Center</p>
        </div>
      </div>
    </div>
  );
};

export default KontaktoniEkipinTone;
